<template>
  <div v-if="!loading" @click="$emit('selected', { id: seed.id, type: seed.type })" class="seed">
    <div>
      <img class="album-art" v-if="seed.type == 'artist'" :src="image" />
      <img class="album-art" v-if="seed.type == 'track'" :src="image" />
      <div class="genre-art" v-if="seed.type == 'genre'" />
    </div>
    <div class="seed-name">{{ info.name || seed.id }}</div>
    <div v-if="seed.type == 'track'" class="seed-info">{{ displayArtists(info) }}</div>
    <div v-if="seed.type == 'artist'" class="seed-info">Artist</div>
    <div v-if="seed.type == 'genre'" class="seed-info">Genre</div>
    <i v-if="closeable" class="closeable fas fa-times"></i>
  </div>
</template>

<script>
import spotify from "../store/spotify"

export default {
  name: "seed",
  props: ["seed", "closeable"],
  data() {
    return {
      info: this.seed,
      loading: true,
    }
  },
  created() {
    const getInfo = async () => {
      if (this.seed.type == "track") return await spotify.getTrack(this.seed.id)
      else if (this.seed.type == "artist") return await spotify.getArtist(this.seed.id)
      else if (this.seed.type == "genre") return this.seed
    }
    this.closeable
      ? getInfo().then(result => {
          this.info = result
          this.loading = false
        })
      : (this.loading = false)
  },
  methods: {
    displayArtists(track) {
      let result = ""
      const suffix = ", "
      const artists = track.artists
      _.forEach(artists, artist => {
        result += `${artist.name}${suffix}`
      })
      return result.slice(0, -suffix.length)
    },
  },
  computed: {
    image() {
      let payload = null
      try {
        if (this.seed.type == "track") payload = this.info.album.images[0].url
        else if (this.seed.type == "artist") payload = this.info.images[0].url
      } catch {
        null
      }
      return payload
    },
  },
  watch: {
    seed() {
      const getInfo = async () => {
        if (this.seed.type == "track") return await spotify.getTrack(this.seed.id)
        else if (this.seed.type == "artist") return await spotify.getArtist(this.seed.id)
        else if (this.seed.type == "genre") return this.seed
      }
      this.closeable ? getInfo().then(result => (this.info = result)) : null
    },
  },
}
</script>

<style lang="scss" scoped>
$backgroundColor: #191919;

.seed {
  color: white;
  border-radius: 25px;
  background-color: $backgroundColor;
  text-align: left;
  margin-bottom: 2px;
  width: 60vw;
  height: 50px;
  margin-left: 15vw;
  margin-bottom: 8px;
  white-space: nowrap;

  cursor: pointer;
}
.seed:hover {
  background-color: darken($backgroundColor, 2);
}
.album-art {
  height: 45px;
  width: 45px;
  float: left;
  margin: 3px;
  border-radius: 50%;
}
.genre-art {
  float: left;
  height: 42px;
  width: 42px;
  margin: 4px;
  border-radius: 50%;
  background-color: #3b3b3b;
}
.seed-name {
  margin-left: 55px;
  padding-top: 8px;
  margin-right: 38px;
  color: rgb(255, 255, 255);
  font-size: 14px;
  text-overflow: ellipsis;
  overflow: hidden;
}
.seed-info {
  text-overflow: ellipsis;
  overflow: hidden;
  margin-right: 38px;
  margin-left: 55px;
  margin-top: -3px;
  color: rgb(92, 92, 92);
  font-size: 12px;
}
.closeable {
  position: relative;
  right: 25px;
  top: -25px;
  font-size: 12px;
  float: right;
  color: rgb(100, 100, 100);
}
</style>

import Vue from "vue"
import Vuetify from "vuetify/lib"
import VuetifyConfirm from "vuetify-confirm"

Vue.use(Vuetify)

const vuetify = new Vuetify({
  icons: {
    iconfont: "mdiSvg",
  },
  theme: {
    dark: true,
    themes: {
      dark: {
        primary: "#ffffff",
        secondary: "#272727", // medium gray
        accent: "#ffa012", // orange
        error: "#B71C1C",
        info: "#1C1C1C", // dark background
        success: "#4CAF50",
        warning: "#FFC107",
      },
    },
  },
})

Vue.use(VuetifyConfirm, {
  vuetify,
  buttonTrueText: "Yes",
  buttonFalseText: "No",
  color: "secondary",
  icon: null,
  width: 350,
  property: "$confirm",
})

export default vuetify

import axios from "axios"
import rateLimit from "axios-rate-limit"
import _ from "lodash"

const stripe = {}

const $backend = rateLimit(
  axios.create({
    baseURL: "https://api.stripe.com/v1",
    timeout: 60000,
  }),
  {
    maxRPS: 9,
  }
)

$backend.interceptors.request.use(config => {
  config.headers.Authorization = `Bearer ${process.env.VUE_APP_STRIPESECRETKEY}`
  config.headers["Content-Type"] = "application/x-www-form-urlencoded"
  config.headers.Accept = "application/json"
  return config
})

$backend.interceptors.response.use(
  response => {
    return response
  },
  error => {
    console.log("Stripe Error: ", error)
    return error
  }
)

stripe.getSubscription = async id => {
  const response = await $backend.get(`subscriptions/${id}`)
  return response.data
}

stripe.getCustomer = async email => {
  const response = await $backend.get(`customers`, {
    params: { email },
  })
  return response.data.data
}

export default stripe

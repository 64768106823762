const uuid = require("uuid/v1")

const section = {
  uuid: uuid(),
  name: "Section ",
  range: [50, 130],
  seeds: [],
  start: false,
  instrumental: false,
  time: 10,
}

export default section

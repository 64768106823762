<template>
  <div>
    <v-toolbar height="65px;" id="top-toolbar">
      <v-btn
        @click="$router.go(-1)"
        class="action-button-styling"
        color="white"
        id="back-button"
        rounded
        x-large
        depressed
        outlined
        dark
      >
        <i class="fa fa-arrow-left" aria-hidden="true"></i>
      </v-btn>
      <div class="title-holder" @click="showEditor = true">
        <p id="title">{{ playlist.name }}</p>
      </div>
      <transition name="fade" mode="out-in">
        <v-container fluid id="playlist-length" v-if="!generating && !init && tracks.length">
          <p>{{ playlistLength }}</p>
        </v-container>
      </transition>
      <v-btn
        v-if="!generating && !init"
        @click="playlistEngine(true)"
        color="white"
        id="regenerate-button"
        rounded
        outlined
        depressed
        x-large
        class="action-button-styling"
        dark
      >
        <i class="fas fa-sync-alt"></i>
      </v-btn>
    </v-toolbar>
    <v-container fluid>
      <transition name="fade" mode="out-in">
        <div id="play-container" v-if="!generating && !init">
          <div
            id="playlist-viewer"
            :class="{
              'playlist-lower': unsavedPlaylistEdits() !== !!device,
              'playlist-higher': unsavedPlaylistEdits() && device,
            }"
          >
            <song
              @tapped="trackClicked(track, index)"
              @refresh="trackRefresh(track)"
              v-for="(track, index) in tracks"
              :key="track.id"
              :loading="isLoading(track)"
              :track="track"
              :drills="playlist.sections"
              :feature="getFeature(track)"
            ></song>
          </div>
        </div>
      </transition>
      <transition name="fade" mode="out-in">
        <v-btn
          v-if="unsavedPlaylistEdits() && !generating && !init"
          @click="savePlaylist"
          id="save-playlist-button"
          key="playlist-button"
          color="secondary"
          elevation="5"
          :loading="saving"
          :class="{
            'text-capitalize': true,
            mobile: mobile,
            desktop: !mobile,
            'save-playlist-higher': device,
            'save-playlist-lower': !device,
          }"
          rounded
          x-large
          >Save Playlist</v-btn
        >
      </transition>
      <transition name="fade" mode="out-in">
        <v-progress-circular
          :size="120"
          :rotate="-90"
          :width="8"
          :value="calculatePercentage"
          id="loading-bar"
          v-if="generating"
          color="#ffffff"
        ></v-progress-circular>
      </transition>
      <transition name="fade" mode="out-in">
        <v-progress-circular
          :size="60"
          :rotate="-90"
          :width="4"
          indeterminate
          id="loading-bar"
          v-if="fetching"
          color="#ffffff"
        ></v-progress-circular>
      </transition>
      <transition name="fade" mode="out-in">
        <editor
          confirmation="Save Name"
          @close="showEditor = false"
          :current="playlist.name"
          @save="saveName"
          v-if="showEditor"
        ></editor>
      </transition>
      <transition name="fade" mode="out-in">
        <seeker @played="currentlyPlayingListener()" id="player" v-show="device && !showEditor"></seeker>
      </transition>
    </v-container>
    <checkout :open="viewCheckout" :persistant="true"></checkout>
    <empty :open="viewEmpty" :section="erroredSection"></empty>
  </div>
</template>

<script>
const _ = require("lodash")
import song from "@/components/song"
import editor from "@/components/editor"
import seeker from "@/components/seeker"
import checkout from "@/components/checkout"
import empty from "@/components/empty"
import Spotify from "../store/spotify"
const firebase = require("firebase")
const d3S = require("d3-scale-chromatic")
const pageName = "Tracks"
export default {
  name: pageName,
  metaInfo: {
    title: pageName,
    titleTemplate: "%s | Cycler",
  },
  components: {
    song,
    editor,
    seeker,
    checkout,
    empty,
  },
  data() {
    return {
      init: true,
      fetching: false,
      generating: false,
      saving: false,
      features: [],
      loadingTrack: null,
      showEditor: false,
      viewCheckout: false,
      viewEmpty: false,
      erroredSection: null,
    }
  },
  watch: {
    init(newValue, oldValue) {
      if (!newValue && oldValue) {
        const This = this
        function checkSubscription() {
          const subscribed = This.user.subscription
          This.viewCheckout = !subscribed ? true : false
        }
        setTimeout(checkSubscription, 2000)
      }
    },
  },
  mounted() {
    firebase.analytics().logEvent("page_view", { page_title: pageName })
    this.$store.dispatch("turnOffShuffle")
    this.$store.dispatch("getActiveDevice").then(() => {
      this.$store.dispatch("getCurrentlyPlaying").then(current => {
        current.is_playing ? this.currentlyPlayingListener() : null
      })
    })

    // to accomodate the async nature of url params
    this.$nextTick(() => {
      if (!this.playlist) this.$router.push("/home")
      else this.playlistEngine(false)
    })
  },
  methods: {
    async saveName(name) {
      const uuid = this.playlistUuid
      const payload = { name, uuid }
      await this.$store.dispatch("updateClass", payload)
      this.showEditor = false
    },
    updateStateSimple(state, value) {
      this.$store.dispatch("updateStateSimple", {
        state,
        value,
      })
    },
    actualMethod() {
      this.$store.dispatch("getCurrentlyPlaying").then(current => {
        if (!current.is_playing) {
          clearInterval(this.intervalId)
          this.$store.dispatch("updateStateSimple", {
            state: "intervalId",
            value: null,
          })
        }
      })
    },
    currentlyPlayingListener() {
      if (!this.intervalId) {
        this.$store.dispatch("updateStateSimple", {
          state: "intervalId",
          value: setInterval(this.actualMethod, 1000),
        })
      }
    },
    isLoading(track) {
      return this.loadingTrack ? this.loadingTrack.id == track.id : false
    },
    async trackRefresh(track) {
      this.loadingTrack = track
      const trackIndex = this.tracks.indexOf(track)
      const tracks = _.cloneDeep(this.tracks)
      const section = _.find(this.playlist.sections, d => d.uuid == track.section)
      let newTrack

      try {
        newTrack = await this.$store.dispatch("refreshTrack", { section, tracks })
      } catch (e) {
        // error refreshing song
        if (e.message && e.message == "no tracks found") {
          this.viewEmpty = true
          this.erroredSection = e.section
          firebase
            .analytics()
            .logEvent("exception", {
              description: `no more tracks found in section ${e.section.uuid}`,
              fatal: false,
            })
          return
        } else return this.errorHandler(e, "error refreshing song")
      }

      tracks[trackIndex] = newTrack
      await this.$store.dispatch("updateStateSimple", { state: "tracks", value: tracks })
      this.features = await this.$store.dispatch("getAudioFeatures", tracks)
      this.loadingTrack = null
    },
    errorHandler(e, message) {
      console.error(message, e)
      this.$snackbar.flash({ content: message, color: "error" })
    },
    trackClicked(track, index) {
      this.$store.dispatch("getActiveDevice").then(() => {
        if (!this.device) {
          this.$snackbar.flash({ content: "please open spotify to play the track!", color: "error" })
        } else {
          if (!this.current.is_playing) {
            this.currentlyPlayingListener()
          }
          this.$store.dispatch("getCurrentlyPlaying").then(() => {
            if (this.current.item) {
              if (this.current.item.id == track.id) {
                if (this.current.is_playing) {
                  return this.$store.dispatch("pausePlayer")
                } else {
                  return this.$store.dispatch("playPlayer")
                }
              }
            }
            const play = _.cloneDeep(this.tracks)
            const queue = play.slice(index)
            return this.$store.dispatch(
              "playTracks",
              queue.map(u => u.uri)
            )
          })
        }
      })
    },
    async savePlaylist() {
      this.saving = true
      let id = _.clone(this.playlist.playlist)
      const exists = await Spotify.getPlaylist(id)

      id = !exists
        ? await this.$store.dispatch("createPlaylist", this.playlist.name)
        : await Promise.resolve(id)

      let tracks = _.cloneDeep(this.tracks)

      await this.$store.dispatch("savePlaylist", { tracks, id })
      tracks = tracks.map(t => {
        return {
          id: t.id,
          section: t.section,
        }
      })

      const uuid = this.playlist.uuid
      const payload = { playlist: id, tracks, uuid }
      await this.$store.dispatch("updateClass", payload)
      this.saving = false
      this.$snackbar.flash({ content: "successfully saved playlist!", color: "success" })
    },
    getFeature(track) {
      return _.find(this.features, f => f.id == track.id)
    },
    unsavedPlaylistEdits() {
      if (!this.playlist.playlist) {
        return true
      }
      if (this.tracks) {
        const localTracks = this.tracks.map(p => p.id)
        const serverTracks = this.playlist.tracks ? this.playlist.tracks.map(t => t.id) : []
        return !_.isEqual(localTracks, serverTracks)
      } else return false
    },
    async playlistEngine(hardRefresh) {
      this.$store.dispatch("updateStateSimple", { state: "tracks", value: [] })
      if (this.playlist.tracks.length && !hardRefresh) {
        this.fetching = true
        let tracks = await this.$store.dispatch(
          "getTracks",
          this.playlist.tracks.map(t => t.id)
        )
        tracks = tracks.map(t => {
          const track = _.find(this.playlist.tracks, ["id", t.id])
          t.section = track.section
          return t
        })
        this.$store.dispatch("updateStateSimple", { state: "tracks", value: tracks })
        this.features = await this.$store.dispatch("getAudioFeatures", tracks)
        this.fetching = false
        this.init = false
      } else {
        this.generating = true
        try {
          // generate playlist from sections
          await this.$store.dispatch("playlistEngine", this.playlist.sections)
        } catch (e) {
          // error generating playlist
          if (e.message && e.message == "no tracks found") {
            this.viewEmpty = true
            this.erroredSection = e.section
          } else this.errorHandler(e, "error generating playlist")
        }
        try {
          // get audio features from playlist tracks
          this.features = await this.$store.dispatch("getAudioFeatures", this.tracks)
          this.generating = false
          this.init = false
          firebase.analytics().logEvent("view_item_list", { items: this.tracks.map(t => t.name) })
        } catch (e) {
          // error fetching audio features for playlist tracks
          this.generating = false
          this.init = false
          const description = "error getting track features from spotify"
          this.errorHandler(e, description)
          firebase.analytics().logEvent("exception", { description, fatal: false })
        }
      }
    },
    millisToMinutesAndSeconds(millis) {
      var minutes = Math.floor(millis / 60000)
      var seconds = ((millis % 60000) / 1000).toFixed(0)
      return minutes + ":" + (seconds < 10 ? "0" : "") + seconds
    },
  },
  computed: {
    user() {
      return this.$store.state.user
    },
    mobile() {
      return this.$store.state.mobile
    },
    playlist() {
      return _.find(this.playlists, ["uuid", this.playlistUuid]) || {}
    },
    playlistUuid() {
      return this.$route.params.uuid
    },
    playlistSectionsTime() {
      let time = 0
      this.playlist.sections.map(d => (time += d.time))
      return time
    },
    tracks() {
      return this.$store.state.tracks
    },
    calculatePercentage() {
      let time = 0
      _.map(this.tracks, track => {
        time += track.duration_ms
      })
      const totalTime = _.clone(this.playlistSectionsTime * 60000)
      const result = time / totalTime
      return result * 100
    },
    colors() {
      return d3S.schemeSet3
    },
    current() {
      return this.$store.state.current
    },
    device() {
      return this.$store.state.device
    },
    intervalId() {
      return this.$store.state.intervalId
    },
    playlists() {
      return this.$store.state.playlists
    },
    playlistLength() {
      let length = 0
      this.tracks.forEach(t => {
        length += t.duration_ms
      })
      return this.millisToMinutesAndSeconds(length)
    },
  },
}
</script>

<style lang="scss" scoped>
$toolbar: #272727;
$light: #cccccc;
.fade-enter {
  opacity: 0;
}
.fade-enter-active {
  transition: opacity 0.15s;
}
.fade-leave-active {
  transition: opacity 0.15s;
  opacity: 0;
}
.title-holder {
  white-space: nowrap;
  position: absolute;
  width: 50vw;
  left: 25vw;
  top: -2px;
}
#title {
  font-size: 22px;
  font-style: bold;
  color: white;
  z-index: 3;
  margin-top: 20px;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
}
#player {
  position: fixed;
  z-index: 5;
  bottom: 0px;
  left: 0px;
}
#top-toolbar {
  position: fixed;
  width: 100vw;
  background-color: $toolbar;
}
#loading-bar {
  margin-top: 40vh;
}
#playlist-length {
  position: absolute;
  top: 72px;
  left: -1px;
  color: $light;
}
.action-button-styling {
  opacity: 0.85;
  border: none;
}
#play-container {
  display: flex;
  justify-content: space-around;
}
#playlist-viewer {
  position: fixed;
  top: 120px;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.playlist-lower {
  bottom: 155px;
}
.playlist-higher {
  bottom: 220px;
}
#back-button {
  position: fixed;
  left: 7px;
  top: 7px;
}
input:focus {
  outline: none;
}
#regenerate-button {
  position: fixed;
  top: 7px;
  right: 7px;
  font-size: 13px;
}
#save-playlist-button {
  position: fixed;
}
#save-playlist-button.mobile {
  width: 70vw;
  left: 15vw;
}
#save-playlist-button.desktop {
  width: 50vw;
  left: 25vw;
}
.save-playlist-lower {
  bottom: 40px;
}
.save-playlist-higher {
  bottom: 150px;
}
</style>

<template>
  <div class="autocomplete">
    <v-text-field
      id="actual-search-bar"
      @focus="searchFocused"
      placeholder="add artist, genre, or song..."
      rounded
      filled
      single-line
      autocomplete="off"
      v-model="search"
      @input="onChange"
    ></v-text-field>
    <v-btn
      v-if="isOpen"
      @click="closeSearch"
      color="white"
      id="close-button"
      class="action-button-styling"
      outlined
      depressed
      rounded
      x-large
      dark
    >
      <i class="fas fa-times"></i>
    </v-btn>
    <ul v-if="isOpen" class="autocomplete-results">
      <seed
        v-for="result in results"
        :key="result.id"
        :seed="result"
        :closeable="false"
        @selected="onSelected"
        class="autocomplete-result"
      ></seed>
    </ul>
  </div>
</template>

<script>
const debounce = require("lodash/debounce")
const _ = require("lodash")

import seed from "@/components/seed"

export default {
  name: "autocomplete",
  data() {
    return {
      search: "",
      results: [],
      isOpen: false,
    }
  },
  components: {
    seed: seed,
  },
  methods: {
    closeSearch() {
      this.isOpen = false
      this.search = ""
      document.getElementById("actual-search-bar").blur()
      this.$emit("close-search")
    },
    onChange: debounce(function() {
      if (this.search.length > 0) {
        this.$store.dispatch("searchTracksAndArtists", this.search).then(results => {
          const searchedGenres = this.genres.filter(g => {
            return g.name.toLowerCase().includes(this.search.toLowerCase())
          })
          this.results = [...searchedGenres, ...results]
        })
      }
    }, 200),
    onSelected(thing) {
      this.search = ""
      this.isOpen = false
      const { id, type, name, images, album, artists } = thing
      let p = { id, type, name, images, album, artists }
      p = _.omitBy(p, _.isNil)
      document.getElementById("actual-search-bar").blur()
      this.$emit("selected", p)
      this.$emit("close-search")
    },
    searchFocused() {
      this.isOpen = true
      this.$emit("search-focused", true)
      this.$nextTick(() => {
        document.getElementById("actual-search-bar").focus()
      })
    },
  },
  computed: {
    genres() {
      return this.$store.state.genres
    },
  },
}
</script>

<style lang="scss" scoped>
.autocomplete-results {
  top: 58px;
  position: absolute;
  bottom: -300px;
  overflow-y: scroll;
  width: 80vw;
  padding-left: 0px;
  opacity: 0.99;
}

.autocomplete-result {
  color: white;
  border-radius: 25px;
  text-align: left;
  margin-bottom: 2px;
  margin-left: 3vw;
  width: 75vw;
  cursor: pointer;
}

.autocomplete-result:hover {
  background-color: #181818;
}

.album-art {
  height: 40px;
  width: 40px;
  float: left;
  margin: 5px;
  border-radius: 50%;
}
.genre-art {
  float: left;
  height: 40px;
  width: 40px;
  margin: 5px;
  border-radius: 50%;
  background-color: #303030;
}
#result-name {
  margin-left: 55px;
  padding-top: 7px;
  color: rgb(255, 255, 255);
  font-size: 16px;
}
#sub-info {
  margin-left: 55px;
  color: rgb(92, 92, 92);
  font-size: 14px;
}
#close-button {
  position: absolute;
  top: 1px;
  right: 0px;
}
.action-button-styling {
  opacity: 0.85;
  border: none;
}
</style>

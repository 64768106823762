var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-toolbar',{attrs:{"height":"65px;","id":"top-toolbar"}},[_c('v-btn',{staticClass:"action-button-styling",attrs:{"color":"white","id":"back-button","rounded":"","x-large":"","depressed":"","outlined":"","dark":""},on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('i',{staticClass:"fa fa-arrow-left",attrs:{"aria-hidden":"true"}})]),_c('div',{staticClass:"title-holder",on:{"click":function($event){_vm.showEditor = true}}},[_c('p',{attrs:{"id":"title"}},[_vm._v(_vm._s(_vm.playlist.name))])]),_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(!_vm.generating && !_vm.init && _vm.tracks.length)?_c('v-container',{attrs:{"fluid":"","id":"playlist-length"}},[_c('p',[_vm._v(_vm._s(_vm.playlistLength))])]):_vm._e()],1),(!_vm.generating && !_vm.init)?_c('v-btn',{staticClass:"action-button-styling",attrs:{"color":"white","id":"regenerate-button","rounded":"","outlined":"","depressed":"","x-large":"","dark":""},on:{"click":function($event){return _vm.playlistEngine(true)}}},[_c('i',{staticClass:"fas fa-sync-alt"})]):_vm._e()],1),_c('v-container',{attrs:{"fluid":""}},[_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(!_vm.generating && !_vm.init)?_c('div',{attrs:{"id":"play-container"}},[_c('div',{class:{
            'playlist-lower': _vm.unsavedPlaylistEdits() !== !!_vm.device,
            'playlist-higher': _vm.unsavedPlaylistEdits() && _vm.device,
          },attrs:{"id":"playlist-viewer"}},_vm._l((_vm.tracks),function(track,index){return _c('song',{key:track.id,attrs:{"loading":_vm.isLoading(track),"track":track,"drills":_vm.playlist.sections,"feature":_vm.getFeature(track)},on:{"tapped":function($event){return _vm.trackClicked(track, index)},"refresh":function($event){return _vm.trackRefresh(track)}}})}),1)]):_vm._e()]),_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(_vm.unsavedPlaylistEdits() && !_vm.generating && !_vm.init)?_c('v-btn',{key:"playlist-button",class:{
          'text-capitalize': true,
          mobile: _vm.mobile,
          desktop: !_vm.mobile,
          'save-playlist-higher': _vm.device,
          'save-playlist-lower': !_vm.device,
        },attrs:{"id":"save-playlist-button","color":"secondary","elevation":"5","loading":_vm.saving,"rounded":"","x-large":""},on:{"click":_vm.savePlaylist}},[_vm._v("Save Playlist")]):_vm._e()],1),_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(_vm.generating)?_c('v-progress-circular',{attrs:{"size":120,"rotate":-90,"width":8,"value":_vm.calculatePercentage,"id":"loading-bar","color":"#ffffff"}}):_vm._e()],1),_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(_vm.fetching)?_c('v-progress-circular',{attrs:{"size":60,"rotate":-90,"width":4,"indeterminate":"","id":"loading-bar","color":"#ffffff"}}):_vm._e()],1),_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(_vm.showEditor)?_c('editor',{attrs:{"confirmation":"Save Name","current":_vm.playlist.name},on:{"close":function($event){_vm.showEditor = false},"save":_vm.saveName}}):_vm._e()],1),_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[_c('seeker',{directives:[{name:"show",rawName:"v-show",value:(_vm.device && !_vm.showEditor),expression:"device && !showEditor"}],attrs:{"id":"player"},on:{"played":function($event){return _vm.currentlyPlayingListener()}}})],1)],1),_c('checkout',{attrs:{"open":_vm.viewCheckout,"persistant":true}}),_c('empty',{attrs:{"open":_vm.viewEmpty,"section":_vm.erroredSection}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
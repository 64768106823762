<template>
  <div>
    <v-toolbar
      v-if="!searchSelected && playlist && !showEditor"
      height="65px;"
      id="top-toolbar"
      :style="{ 'border-bottom': bottomBorder }"
    >
      <v-container fluid>
        <v-btn
          @click="toggleInstrumental()"
          class="inst-button"
          rounded
          :color="section.instrumental ? 'white' : '#555555'"
          x-large
          depressed
          outlined
          v-blur
        >
          <i class="fas fa-drum"></i>
        </v-btn>
        <div v-if="section" @click="showEditor = true">
          <p id="title">{{ section.name }}</p>
        </div>
        <v-btn
          @click="closeButtonClicked()"
          class="close-button"
          color="white"
          rounded
          x-large
          depressed
          outlined
          dark
        >
          <i class="fas fa-times"></i>
        </v-btn>
      </v-container>
    </v-toolbar>
    <v-container id="page-container" v-if="playlist" fluid>
      <div class="bpm-slider">
        <div v-if="!searchSelected">
          <div id="double-range-slider">
            <p class="bpm-title">bpm</p>
            <div>
              <v-range-slider
                id="slider"
                step="5"
                ticks="always"
                color="#fff"
                v-model="section.range"
                :max="bpm.max"
                :min="bpm.min"
              >
                <template v-slot:prepend>
                  <div id="lower-range">
                    <v-text-field
                      id="lower-range-input"
                      v-model="section.range[0]"
                      class="bpm-range"
                      type="number"
                      style="width: 43px"
                    ></v-text-field>
                  </div>
                </template>
                <template v-slot:append>
                  <div id="outer-range">
                    <v-text-field
                      id="outer-range-input"
                      v-model="section.range[1]"
                      class="bpm-range"
                      type="number"
                      style="width: 43px"
                    ></v-text-field>
                  </div>
                </template>
              </v-range-slider>
            </div>
          </div>
        </div>
        <search
          @selected="seedSelected"
          @search-focused="searchSelected = true"
          @close-search="searchSelected = false"
          :class="{ 'search-bar': true, higher: searchSelected, lower: !searchSelected }"
        ></search>
        <div v-if="!searchSelected" id="seed-container">
          <div :key="seed.id" v-for="seed in section.seeds">
            <v-btn
              @click="seedStarred(seed)"
              v-if="seed.type != 'genre'"
              :class="{
                'star-song': section.starred.includes(seed.id),
                'non-star-song': !section.starred.includes(seed.id),
                'star-mobile': mobile,
              }"
              rounded
              outlined
              depressed
            >
              <i v-if="!section.starred.includes(seed.id)" class="far fa-star"></i>
              <i v-else class="fas fa-star"></i>
            </v-btn>
            <seed :seed="seed" :closeable="true" @selected="seedSelected" class="autocomplete-result"></seed>
          </div>
        </div>
      </div>
      <v-btn
        v-if="!searchSelected"
        @click="saveSection"
        rounded
        :loading="saving"
        id="generate-btn"
        :class="{ 'text-capitalize': true, mobile: mobile, desktop: !mobile }"
        color="secondary"
        elevation="5"
        dark
        x-large
        >Save Section</v-btn
      >
      <transition name="fade" mode="out-in">
        <editor
          :confirmation="'Save Name'"
          @close="showEditor = false"
          :current="section.name"
          @save="saveName"
          v-if="showEditor"
        ></editor>
      </transition>
    </v-container>
  </div>
</template>

<script>
const _ = require("lodash")
import search from "@/components/search"
import seed from "@/components/seed"
import editor from "@/components/editor"

const d3S = require("d3-scale-chromatic")
const firebase = require("firebase")

export default {
  name: "class-section",
  metaInfo: {
    title: "Section",
    titleTemplate: "%s | Cycler",
  },
  components: {
    seed,
    search,
    editor,
  },
  data() {
    return {
      section: {},
      playlist: {},
      bpm: {
        max: 180,
        min: 40,
      },
      saving: false,
      searchSelected: false,
      showEditor: false,
    }
  },
  mounted() {
    firebase.analytics().logEvent("page_view", { page_title: "Section" })
    // to accomodate the async nature of url params
    this.$nextTick(() => {
      this.playlist = _.find(this.playlists, ["uuid", this.playlistUuid])
      const exists = _.find(this.playlist.sections, ["uuid", this.sectionUuid])
      if (exists) {
        this.section = _.cloneDeep(exists)
        if (!this.section.starred) this.section.starred = []
      } else {
        this.section.name = `Section ${this.playlist.sections.length + 1}`
        this.section.time = 10
        this.section.range = [50, 130]
        this.section.starred = []
        this.section.instrumental = false
        this.section.seeds = []
        this.section.uuid = this.sectionUuid
      }
    })
  },
  methods: {
    toggleInstrumental() {
      this.section.instrumental = !this.section.instrumental
      if (this.section.instrumental) {
        this.$snackbar.flash({ content: "instrumental mode on", color: "success" })
      } else {
        this.$snackbar.flash({ content: "instrumental mode off", color: "info" })
      }
      this.$forceUpdate()
    },
    seedStarred(seed) {
      const i = this.section.starred.indexOf(seed.id)
      if (i > -1) this.section.starred.splice(i, 1)
      else {
        if (seed.type == "artist") {
          const starredSeeds = _.filter(this.section.seeds, seed => this.section.starred.includes(seed.id))
          const starredArtists = _.filter(starredSeeds, ["type", "artist"])
          if (starredArtists.length) {
            const sid = starredArtists[0].id
            const ii = this.section.starred.indexOf(sid)
            this.section.starred.splice(ii, 1)
          }
        }
        this.section.starred.push(seed.id)
      }
      this.$forceUpdate()
    },
    closeButtonClicked() {
      if (!this.playlist.sections.length) this.$router.push("/home")
      else this.$router.go(-1)
    },
    async saveSection() {
      if (!this.section.seeds.length)
        return this.$snackbar.flash({ content: "please add an artist, genre, or song!", color: "error" })
      this.saving = true
      const uuid = this.playlistUuid
      const sections = this.playlist.sections

      const existing = _.find(this.playlist.sections, ["uuid", this.sectionUuid])
      if (existing) {
        const index = sections.indexOf(existing)
        sections[index] = this.section
      } else {
        sections.push(this.section)
      }
      await this.$store.dispatch("updateClass", { uuid, sections })
      this.$router.go(-1)
    },
    isSeedSelected(seed) {
      return _.find(this.section.seeds, ["id", seed.id])
    },
    seedSelected(seed) {
      if (this.isSeedSelected(seed)) {
        this.section.seeds.splice(this.section.seeds.indexOf(this.isSeedSelected(seed)), 1)
        if (this.section.starred.includes(seed.id)) {
          const i = this.section.starred.indexOf(seed.id)
          this.section.starred.splice(i, 1)
        }
      } else {
        if (this.section.seeds.length == 5) {
          this.$snackbar.flash({ content: "please remove one before adding another!", color: "error" })
        } else {
          this.section.seeds.push(seed)
        }
      }
    },
    saveName(name) {
      this.section.name = name
      this.showEditor = false
    },
  },
  computed: {
    bottomBorder() {
      if (this.playlist.sections) {
        const exists = _.find(this.playlist.sections, ["uuid", this.sectionUuid])
        if (exists) {
          const index = this.playlist.sections.indexOf(exists)
          return `3px solid ${this.colors[index]}`
        } else {
          return `3px solid ${this.colors[this.playlist.sections.length]}`
        }
      } else {
        return "3px solid white"
      }
    },
    playlistUuid() {
      return this.$route.params.uuid
    },
    sectionUuid() {
      return this.$route.params.id
    },
    colors() {
      return d3S.schemeSet3
    },
    playlists() {
      return this.$store.state.playlists
    },
    mobile() {
      return this.$store.state.mobile
    },
  },
}
</script>

<style lang="scss" scoped>
$toolbar: #272727;
$light: #cccccc;

.fade-enter {
  opacity: 0;
}
.fade-enter-active {
  transition: opacity 0.15s;
}
.fade-leave-active {
  transition: opacity 0.15s;
  opacity: 0;
}
#page-container {
  height: calc(100vh - 65px);
}
#switch {
  position: absolute;
  padding-top: 24px;
}
#switch-label {
  margin-left: 0px;
  font-size: 16px;
  color: #a8a8a8;
}

#title {
  font-size: 22px;
  font-style: bold;
  color: white;
  z-index: 3;
  margin-top: 20px;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
}
.star-song {
  float: left;
  margin-left: 6vw;
  margin-top: 8px;
  border: none;
}
.non-star-song {
  color: gray;
  float: left;
  margin-left: 6vw;
  margin-top: 8px;
  border: none;
}
.star-mobile {
  margin-left: -10px;
}
.close-button {
  position: fixed;
  right: 6px;
  top: 6px;
  opacity: 0.85;
  border: none;
}
.inst-button {
  position: fixed;
  left: 6px;
  top: 6px;
  opacity: 0.85;
  border: none;
}
.bpm-slider {
  position: absolute;
  width: 90vw;
  left: 5vw;
  margin-top: 80px;
}
.bpm-range {
  margin: 0px !important;
  margin-top: -3px !important;
  margin-left: 18px !important;
  font-size: 18px;
  padding: 0px;
  margin-top: -6px;
  margin-left: 30px;
}
.bpm-title {
  font-size: 12px;
  margin-bottom: 0px;
  color: darken($light, 10);
}
#top-toolbar {
  position: fixed;
  width: 100vw;
  background-color: $toolbar;
  z-index: 10;
}
input:focus {
  outline: none;
}
#generate-btn {
  position: fixed;
  bottom: 40px;
}
#generate-btn.mobile {
  width: 70vw;
  left: 15vw;
}
#generate-btn.desktop {
  width: 50vw;
  left: 25vw;
}
#seed-container {
  margin-top: 90px;
}
.search-bar {
  position: absolute;
  margin-top: 15px;
  z-index: 5;
  width: 80vw;
  margin-left: 5vw;
}
.higher {
  top: -82px;
}
.lower {
  top: 70px;
}
#double-range-slider {
  position: relative;
  width: 88vw;
  left: 1vw;
}
</style>

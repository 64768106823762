<template>
  <v-footer v-bind="localAttrs" :padless="padless">
    <v-card color="info" flat tile width="100%" height="70px" class="text-center">
      <v-divider></v-divider>
      <v-card-text>
        <a style="padding-right: 12px" href="/terms">terms</a>
        <v-btn @click="goTo(i.link)" v-for="i in icons" :key="i.icon" class="mx-4" icon>
          <v-icon size="24px">
            {{ i.icon }}
          </v-icon>
        </v-btn>
        <a style="padding-left: 12px" href="/privacy">privacy</a>
      </v-card-text>
    </v-card>
  </v-footer>
</template>

<script>
export default {
  data: () => ({
    icons: [
      { icon: "mdi-email", link: "mailto:info@cycler.io" },
      {
        icon: "mdi-spotify",
        link: "https://open.spotify.com/user/t62jfw7ylx01pqgrcqyrrlhod?si=DXBuqofOQB-uQMW0H_-3wg",
      },
      { icon: "mdi-instagram", link: "https://www.instagram.com/cycler.io/" },
    ],
    padless: true,
    variant: "absolute",
  }),
  methods: {
    goTo(link) {
      window.open(link)
    },
  },
  computed: {
    localAttrs() {
      const attrs = {}

      if (this.variant === "default") {
        attrs.absolute = false
        attrs.fixed = false
      } else {
        attrs[this.variant] = true
      }
      return attrs
    },
  },
}
</script>
<style scoped>
a {
  text-decoration: none;
}
</style>

<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="400">
      <v-card>
        <v-btn icon x-large id="close-button" @click="$emit('closed')"><i class="fas fa-times"></i></v-btn>
        <v-container fluid>
          <img id="logo" src="@/assets/app-logo.png" />
          <p class="title-text">
            welcome to a year of cycler
          </p>
          <p class="detail-text">
            you will be charged a prorated amount based on where you are in your current billing cycle at the
            time you switch plans. your new plan will be charged to your default payment method and renew
            automatically.
          </p>
        </v-container>
        <div class="table-container" v-if="invoice">
          <v-data-table
            :headers="[
              { text: 'description', value: 'description' },
              { text: 'amount', value: 'amount' },
            ]"
            :items="invoice.lines.data"
            hide-default-footer
            disable-sort
            :mobile-breakpoint="50"
          >
            <template v-slot:[`item.description`]="{ item }">
              <p class="description-text">{{ item.description.toLowerCase().replace("time", "credit") }}</p>
            </template>
            <template v-slot:[`item.amount`]="{ item }">
              <p>{{ getDollarsAndCents(item.amount) }}</p>
            </template>
          </v-data-table>
        </div>
        <v-card-actions style="padding-bottom: 24px; padding-top: 0px;">
          <v-container fluid>
            <v-btn
              id="start-trial-btn"
              class="text-lowercase"
              rounded
              x-large
              color="#242424"
              dark
              :loading="loading"
              @click="upgradeSubscription()"
            >
              upgrade
            </v-btn>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: "upgrade",
  props: ["open", "invoice", "prorationDate"],
  data() {
    return {
      dialog: false,
      loading: false,
    }
  },
  methods: {
    flashMessage(content, color) {
      this.$snackbar.flash({ content, color })
    },
    async upgradeSubscription() {
      this.loading = true
      try {
        await fetch(process.env.VUE_APP_API + "/upgradeSubscription", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            subscriptionId: this.invoice.subscription,
            priceId: process.env.VUE_APP_STRIPEYEARLY,
            prorationDate: this.prorationDate,
          }),
        })
        this.flashMessage("successfully updated subscription!", "success")
        this.$emit("closed")
      } catch (error) {
        this.flashMessage(error, "error")
      }
      this.loading = false
    },
    getDollarsAndCents(num) {
      let dollars = num / 100
      return dollars.toLocaleString("en-US", { style: "currency", currency: "USD" })
    },
  },
  watch: {
    open() {
      this.dialog = this.open
    },
    invoice() {
      if (this.invoice) this.invoice.lines.data.push({ description: "total", amount: this.invoice.total })
    },
  },
  computed: {
    user() {
      return this.$store.state.user
    },
  },
}
</script>

<style lang="scss" scoped>
.table-container {
  padding: 12px;
  padding-top: 0px;
}
.detail-text {
  font-size: 10px;
  padding-left: 20px;
  padding-right: 20px;
}
.description-text {
  font-size: 12px;
  color: darken(white, 20);
  margin-bottom: 0px;
}
.title-text {
  font-size: 20px;
}
#start-trial-btn {
  padding: 20px;
}
.price-btn {
  margin: 0px 6px;
}
#selected-btn {
  background-color: #858585;
}
#unselected-btn {
  color: #242424;
}
.price-info {
  margin-top: 12px;
}
.price {
  font-size: 24px;
}
.title {
  margin-top: 12px;
  // font-size: 24px !important;
}
#logo {
  height: 60px;
  margin-top: 12px;
}
#close-button {
  position: absolute;
  right: 9px;
  top: 9px;
  font-size: 12px;
  text-decoration: none;
}
.line-item {
  font-size: 10px;
}
</style>

<template>
  <div id="header-bar">
    <v-btn
      :loading="loading"
      id="sign-in-header-btn"
      rounded
      @click="
        login()
        loading = true
      "
      color="accent"
      elevation="8"
      :class="{ 'text-lowercase': true, mobile: mobile, desktop: !mobile }"
      >sign in</v-btn
    >
    <div @click="clicked()">
      <img id="logo-header" src="@/assets/app-logo.png" />
      <p id="title-header">cycler</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "customHeader",
  props: ["clickable"],
  data: () => ({
    loading: false,
  }),
  methods: {
    login() {
      window.location.replace(
        `https://accounts.spotify.com/authorize?client_id=${process.env.VUE_APP_CLIENTID}&response_type=code&redirect_uri=${process.env.VUE_APP_REDIRECTURI}&scope=${this.scopes}&show_dialog=${this.showDialog}`
      )
    },
    clicked() {
      if (this.clickable) this.$router.go(-1)
    },
  },
  computed: {
    scopes() {
      return this.$store.state.scopes.join(" ")
    },
    mobile() {
      return this.$store.state.mobile
    },
    showDialog() {
      return this.$store.state.showDialog
    },
  },
}
</script>
<style scoped>
#header-bar {
  position: fixed;
  top: 0px;
  z-index: 10;
  width: 100vw;
  height: 70px;
  background-color: #1c1c1c;
}
#sign-in-header-btn.desktop {
  position: fixed;
  top: 16px;
  right: 16px;
}
#sign-in-header-btn.mobile {
  position: fixed;
  top: 14px;
  right: 18px;
}
#logo-header {
  height: 40px;
  position: absolute;
  top: 16px;
  left: 16px;
}
#title-header {
  font-size: 30px;
  position: absolute;
  top: 16px;
  left: 65px;
  cursor: pointer;
}
</style>

<template>
  <v-overlay color="info" :opacity="1" :value="true">
    <div id="input" :class="{ mobile, desktop: !mobile }">
      <v-text-field
        @keypress.enter="save()"
        autofocus
        rounded
        autocomplete="off"
        filled
        :placeholder="placeholder || ''"
        single-line
        v-model="name"
      ></v-text-field>
    </div>
    <v-btn
      @click="save()"
      :class="{ 'text-capitalize': true, mobile: mobile, desktop: !mobile }"
      id="save-button"
      color="secondary"
      rounded
      x-large
      dark
      >{{ confirmation }}</v-btn
    >
    <v-btn @click="close()" x-large depressed outlined id="close-button" rounded dark>
      <i class="fas fa-times"></i>
    </v-btn>
  </v-overlay>
</template>

<script>
export default {
  name: "editor",
  props: ["current", "confirmation", "placeholder"],
  mounted() {
    this.current ? this.set() : this.clear()
  },
  data() {
    return {
      name: "",
    }
  },
  methods: {
    clear() {
      this.name = ""
    },
    set() {
      this.name = _.clone(this.current)
    },
    save() {
      this.$emit("save", this.name)
    },
    close() {
      this.$emit("close")
    },
  },
  computed: {
    mobile() {
      return this.$store.state.mobile
    },
  },
}
</script>

<style lang="scss" scoped>
#close-button {
  position: fixed;
  top: 6px;
  right: 6px;
  opacity: 0.85;
  border: none;
}
#save-button.desktop {
  position: fixed;
  bottom: 40px;
  width: 50vw;
  left: 25vw;
}
#save-button.mobile {
  position: fixed;
  bottom: 40px;
  width: 70vw;
  left: 15vw;
}
#input.desktop {
  margin-top: -50px;
  width: 50vw;
}
#input.mobile {
  margin-top: -50px;
  width: 80vw;
}
</style>

const uuid = require("uuid/v1")

const playlist = {
  uuid: uuid(),
  name: "Sample Playlist",
  sections: [],
  playlist: null,
  tracks: [],
}

export default playlist

const snackbarPlugin = {
  install: (Vue, { store }) => {
    if (!store) throw new Error("please provide vuex store.")
    Vue.prototype.$snackbar = {
      flash: function({ content = "", color = "info", timeout = 3000 }) {
        store.commit("snackbarFlash", { content, color, timeout })
      },
    }
  },
}
export default snackbarPlugin

import section from "./section"
import playlist from "./playlist"
import _ from "lodash"

const template = _.cloneDeep(playlist)

template.sections = [
  {
    ...section,
    ...{
      name: "Warmup",
      uuid: "408d4190-3c79-11ea-908d-ed3e1c30f4fc",
      range: [95, 115],
      time: 5,
      seeds: [
        { id: "6LHsnRBUYhFyt01PdKXAF5", type: "artist" },
        { id: "5Pb27ujIyYb33zBqVysBkj", type: "artist" },
      ],
    },
  },
  {
    ...section,
    ...{
      name: "Climb",
      uuid: "4b270820-3c79-11ea-908d-ed3e1c30f4fc",
      range: [60, 80],
      time: 15,
      seeds: [
        { id: "6cEuCEZu7PAE9ZSzLLc2oQ", type: "artist" },
        { id: "7CajNmpbOovFoOoasH2HaY", type: "artist" },
      ],
    },
  },
  {
    ...section,
    ...{
      name: "Flat",
      uuid: "54e89720-3c79-11ea-908d-ed3e1c30f4fc",
      range: [90, 110],
      time: 10,
      seeds: [
        { id: "28j8lBWDdDSHSSt5oPlsX2", type: "artist" },
        { id: "4sTQVOfp9vEMCemLw50sbu", type: "artist" },
      ],
    },
  },
  {
    ...section,
    ...{
      name: "Climb",
      uuid: "5e34f620-3c79-11ea-908d-ed3e1c30f4fc",
      range: [60, 80],
      time: 15,
      seeds: [
        { id: "edm", type: "genre" },
        { id: "2CIMQHirSU0MQqyYHq0eOx", type: "artist" },
      ],
    },
  },
]

export default template

import section from "./section"
import playlist from "./playlist"
import template from "./template"

const payloads = {
  section,
  playlist,
  template,
}
export default payloads

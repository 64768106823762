import $store from "../store"

const request = require("request")
const axios = require("axios")
const rateLimit = require("axios-rate-limit")
const _ = require("lodash")

// creates an axios backend for spotify api calls
const $authorizedBackend = rateLimit(
  axios.create({
    baseURL: "https://api.spotify.com/v1",
    timeout: 60000,
  }),
  {
    maxRPS: 9,
  }
)

$authorizedBackend.interceptors.request.use(config => {
  config.headers.Authorization = `Bearer ${$store.state.accessToken || $store.state.appToken}`
  return config
})

$authorizedBackend.interceptors.response.use(
  response => {
    return response
  },
  error => {
    console.log("Error: ", error)
    const code = error.response.status
    if (code === 401) {
      $store.dispatch("refreshTokens", $store.state.refreshToken).then(() => {
        console.log("Token Refreshed!")
        return error
      })
    }
  }
)

let Spotify = {}

Spotify.getAppToken = () => {
  return new Promise((resolve, reject) => {
    const options = {
      url: `${process.env.VUE_APP_API}/getAppToken/`,
    }
    request.post(options, (error, response, body) => {
      if (!error && response.statusCode === 200) {
        resolve(JSON.parse(body))
      } else {
        reject(error)
      }
    })
  })
}

Spotify.getTokens = payload => {
  const { code, redirect } = payload
  return new Promise((resolve, reject) => {
    const options = {
      url: `${process.env.VUE_APP_API}/getTokens/`,
      form: {
        code,
        redirect,
      },
    }
    request.post(options, (error, response, body) => {
      if (!error && response.statusCode === 200) {
        resolve(JSON.parse(body))
      } else {
        reject(error)
      }
    })
  })
}

Spotify.refreshTokens = refreshToken => {
  return new Promise((resolve, reject) => {
    const options = {
      url: `${process.env.VUE_APP_API}/refreshTokens/`,
      form: {
        refresh_token: refreshToken,
      },
    }
    request.post(options, (error, response, body) => {
      if (!error && response.statusCode === 200) {
        resolve(JSON.parse(body))
      } else {
        reject(error)
      }
    })
  })
}

Spotify.getGrenres = () => {
  return new Promise((resolve, reject) => {
    spotifyRequest("recommendations/available-genre-seeds").then(response => {
      resolve(response)
    })
  })
}

Spotify.getUser = () => {
  return new Promise((resolve, reject) => {
    $authorizedBackend
      .get("me")
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

Spotify.getDevices = () => {
  return new Promise((resolve, reject) => {
    $authorizedBackend
      .get("me/player/devices")
      .then(response => {
        resolve(response.data.devices)
      })
      .catch(error => {
        reject(error)
      })
  })
}

Spotify.turnOffShuffle = () => {
  return new Promise((resolve, reject) => {
    $authorizedBackend
      .put("me/player/shuffle?state=false")
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  })
}

Spotify.getCurrentlyPlaying = () => {
  return new Promise((resolve, reject) => {
    $authorizedBackend
      .get("me/player/currently-playing")
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

Spotify.getPlaylist = async id => {
  const userId = $store.state.user.id
  const response = await $authorizedBackend.get(`users/${userId}/playlists`)
  const playlists = response.data.items
  const found = _.find(playlists, ["id", id])
  return !!found
}

Spotify.createPlaylist = (userId, name) => {
  return new Promise((resolve, reject) => {
    $authorizedBackend
      .post(`users/${userId}/playlists`, {
        name: name,
      })
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

Spotify.savePlaylist = (tracks, id) => {
  return new Promise((resolve, reject) => {
    $authorizedBackend
      .put(`playlists/${id}/tracks`, {
        uris: tracks.map(t => t.uri),
      })
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

Spotify.pausePlayer = deviceId => {
  return new Promise((resolve, reject) => {
    $authorizedBackend
      .put(`me/player/pause?device_id=${deviceId}`)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  })
}

Spotify.playPlayer = deviceId => {
  return new Promise((resolve, reject) => {
    $authorizedBackend
      .put(`me/player/play?device_id=${deviceId}`)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  })
}

Spotify.playTracks = async (deviceId, uris) => {
  const response = await request.put(`https://api.spotify.com/v1/me/player/play?device_id=${deviceId}`, {
    body: JSON.stringify({
      uris: uris,
    }),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${$store.state.accessToken}`,
    },
  })
  return response
}

Spotify.seek = (time, id) => {
  return new Promise((resolve, reject) => {
    $authorizedBackend
      .put(`me/player/seek?device_id=${id}&position_ms=${time}`)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  })
}

const getItemsFromResponseData = data => {
  if (data.items) {
    return data.items
  } else if (data.playlists) {
    return data.playlists.items
  } else if (data.categories) {
    return data.categories.items
  } else if (data.tracks) {
    return data.tracks
  } else if (data.type == "track") {
    return data
  } else if (data.genres) {
    return data.genres
  } else {
    console.error("Unhandled Response from Spotify API: ", data)
  }
}

const spotifyRequest = (url, limit) => {
  return new Promise((resolve, reject) => {
    $authorizedBackend
      .get(url, {
        params: {
          limit: limit,
        },
      })
      .then(result => {
        const data = result.data
        let items = getItemsFromResponseData(data)
        const total = data.total
        const remaining = total - limit
        if (remaining > 0) {
          const requests = new Array(45).map((r, index) => {
            const offset = index * limit + limit
            return $authorizedBackend.get(url, {
              params: { limit, offset },
            })
          })
          Promise.all(requests)
            .then(results => {
              let additionalItems = results.map(t => getItemsFromResponseData(t.data))
              items.push(..._.flatten(additionalItems))
              resolve(items)
            })
            .catch(error => {
              reject(error)
            })
        } else {
          resolve(items)
        }
      })
      .catch(error => {
        reject(error)
      })
  })
}

Spotify.getTracks = ids => {
  return new Promise((resolve, reject) => {
    const url = `tracks?ids=${ids.join(",")}`
    spotifyRequest(url)
      .then(tracks => {
        resolve(tracks)
      })
      .catch(error => {
        reject(error)
      })
  })
}

const buildRecommendationsUrls = section => {
  const lowTempo = section.range[0]
  const highTempo = section.range[1]
  const instrumental = section.instrumental

  let urls = [`recommendations?market=from_token&min_tempo=${lowTempo}&max_tempo=${highTempo}`]
  // if (highTempo <= 90) {
  //   urls.push(`recommendations?market=from_token&min_tempo=${lowTempo * 2}&max_tempo=${highTempo * 2}`)
  // }
  return _.map(urls, url => {
    const tracks = section.seeds.filter(s => s.type == "track")
    const artists = section.seeds.filter(s => s.type == "artist")
    const genres = section.seeds.filter(s => s.type == "genre")
    if (tracks.length > 0) url += `&seed_tracks=${tracks.map(t => t.id).join(",")}`
    if (artists.length > 0) url += `&seed_artists=${artists.map(t => t.id).join(",")}`
    if (genres.length > 0) url += `&seed_genres=${genres.map(t => t.id).join(",")}`
    if (instrumental) url += `&min_instrumentalness=${0.8}`
    return url
  })
}

Spotify.findPlaylistTracks = (section, playlist) => {
  return new Promise(async (resolve, reject) => {
    // returns a list of tracks (usually one unless multiple starred)
    let { starred, seeds } = section
    if (!starred) starred = []
    if (!seeds) seeds = []

    const starredSeeds = _.filter(seeds, seed => starred.includes(seed.id))
    const starredTracks = _.filter(starredSeeds, ["type", "track"])
    const starredArtists = _.filter(starredSeeds, ["type", "artist"])

    if (starredTracks.length) {
      // make sure starred songs don't exist already in playlist
      const found = starredTracks.map(t => t.id).some(id => playlist.map(p => p.id).includes(id))
      if (!found) {
        let tracks = await Spotify.getTracks(starredTracks.map(s => s.id))
        tracks = tracks.map(t => {
          t.section = section.uuid
          return t
        })
        resolve(tracks)
      }
    }

    const limit = 100
    const urls = buildRecommendationsUrls(section)
    const promises = urls.map(url => spotifyRequest(url, limit))
    const results = await Promise.all(promises)
    let possibleTracks = _.uniqBy(_.flatten(results), "id")
    possibleTracks = _.filter(possibleTracks, t => !_.find(playlist, p => p.id == t.id))
    if (!possibleTracks.length) return reject({ message: "no tracks found", section })

    if (starredArtists.length) {
      const filtered = _.filter(possibleTracks, track => {
        const artistIds = track.artists.map(artist => artist.id)
        return starredArtists.map(a => a.id).some(id => artistIds.includes(id))
      })
      if (filtered.length) possibleTracks = filtered
      else return reject({ message: "no tracks found", section })
    }

    console.log("Tracks Found: ", possibleTracks.length)
    const track = possibleTracks[Math.floor(Math.random() * possibleTracks.length)]
    track.section = section.uuid
    resolve([track])
  })
}

Spotify.searchTracksAndArtists = keyword => {
  return new Promise((resolve, reject) => {
    const limit = 10
    $authorizedBackend
      .get("search", {
        params: {
          q: keyword,
          type: "artist,track",
          limit: limit,
        },
      })
      .then(result => {
        const artists = result.data.artists.items
        const tracks = result.data.tracks.items
        resolve([...artists, ...tracks])
      })
      .catch(error => {
        reject(error)
      })
  })
}

Spotify.getAnalysis = track => {
  return new Promise((resolve, reject) => {
    $authorizedBackend
      .get(`audio-analysis/${track.id}`)
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

Spotify.getTrack = id => {
  return new Promise((resolve, reject) => {
    $authorizedBackend
      .get(`tracks/${id}`)
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

Spotify.getArtist = id => {
  return new Promise((resolve, reject) => {
    $authorizedBackend
      .get(`artists/${id}`)
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

Spotify.nextPlayer = deviceId => {
  return new Promise((resolve, reject) => {
    $authorizedBackend
      .post(`me/player/next?device_id=${deviceId}`)
      .then(() => {
        resolve()
      })
      .catch(errors => {
        reject(errors)
      })
  })
}

Spotify.getAudioFeature = id => {
  return new Promise((resolve, reject) => {
    $authorizedBackend
      .get(`audio-features/${id}`)
      .then(result => {
        resolve(result.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

Spotify.getAudioFeatures = tracks => {
  return new Promise((resolve, reject) => {
    // Spotify API accepts 100 ids maximum per request
    const ids = tracks.map(t => t.id)
    const limit = 100
    const chunkedIds = _.chunk(ids, limit)
    const promises = chunkedIds.map(idList => {
      const idCollection = idList.join(",")
      return $authorizedBackend.get("audio-features", {
        params: {
          ids: idCollection,
        },
      })
    })
    Promise.all(promises)
      .then(responses => {
        const features = responses.map(r => {
          return r ? r.data.audio_features : reject("No Audio Features??")
        })
        resolve(_.flatten(features))
      })
      .catch(errors => {
        reject(errors)
      })
  })
}

export default Spotify

<template>
  <v-container fluid id="page-container">
    <customHeader :clickable="false"></customHeader>
    <div id="first-page">
      <p id="title-text" :class="{ 'mobile-title': mobile, 'desktop-title': !mobile }">
        <strong
          >create tempo driven playlists in seconds for
          <transition name="fade" mode="out-in">
            <strong :key="displayText"> {{ displayText }}. </strong>
          </transition>
        </strong>
      </p>
      <v-btn
        :loading="mainButtonLoading"
        @click="
          login()
          mainButtonLoading = true
        "
        color="accent"
        rounded
        id="login-with-spotify-btn"
        :class="{ 'text-lowercase': true, mobile, desktop: !mobile }"
        x-large
        elevation="8"
        ><i id="spotify-icon" class="fab fa-spotify"></i>login with spotify</v-btn
      >
      <v-container fluid>
        <div id="learn-more" @click="scrollTo('second-page')" :class="{ mobile: mobile, desktop: !mobile }">
          <p>learn more</p>
          <i class="fas fa-chevron-down"></i>
        </div>
      </v-container>
    </div>
    <v-container id="second-page" :class="{ mobile, desktop: !mobile }">
      <v-row :class="{ 'create-a-class': true, mobile, desktop: !mobile }">
        <v-col md="6" :class="{ 'text-column': true, mobile, desktop: !mobile }">
          <div style="text-align: right;">
            <p :class="{ 'demo-title': true, mobile, desktop: !mobile }"><strong>create a class</strong></p>
            <p :class="{ 'demo-text': true, mobile, desktop: !mobile }">
              design a {{ total }} minute class in seconds. set a duration for each section and sequence them
              however you'd like!
            </p>
          </div>
        </v-col>
        <v-col md="6" :class="{ column: true, mobile, desktop: !mobile }" id="sections-container">
          <div :class="{ 'v-card': true }" v-for="(section, i) in playlist.sections" :key="section.uuid + i">
            <v-card
              :hover="true"
              @click="selectedSectionId = section.uuid"
              :class="{ selectedSection: isSelected(section) }"
              :style="{ 'border-right': createSideBorder(section, 8) }"
            >
              <v-btn v-if="!mobile" class="sort-handle" color="grey" depressed outlined rounded dark>
                <i class="fas fa-bars"></i>
              </v-btn>
              <v-card-title
                :class="{ 'card-title': true, 'text-capitalize': true, mobile, desktop: !mobile }"
                >{{ section.name }}</v-card-title
              >
              <v-card-subtitle :class="{ 'card-range': true, mobile, desktop: !mobile }">{{
                displayRange(section)
              }}</v-card-subtitle>
            </v-card>
            <v-select
              v-if="!mobile"
              v-model="playlist.sections[i].time"
              class="minute-selecter"
              item-value="value"
              menu-props="auto"
              hide-details
              label="5 min"
              :items="dropdown_edit"
              single-line
            ></v-select>
          </div>
        </v-col>
      </v-row>
      <v-row style="padding-top: 0px">
        <v-col md="6" :class="{ 'search-and-slider': true, mobile, desktop: !mobile }">
          <div>
            <p class="bpm-title">bpm</p>
            <v-range-slider
              step="5"
              ticks="always"
              color="#fff"
              v-model="selectedSection.range"
              :max="140"
              :min="50"
            >
              <template v-slot:prepend>
                <div id="lower-range">
                  <v-text-field
                    id="lower-range-input"
                    v-model="selectedSection.range[0]"
                    class="bpm-range"
                    type="number"
                    style="width: 43px"
                  ></v-text-field>
                </div>
              </template>
              <template v-slot:append>
                <div id="outer-range">
                  <v-text-field
                    id="outer-range-input"
                    v-model="selectedSection.range[1]"
                    class="bpm-range"
                    type="number"
                    style="width: 43px"
                  ></v-text-field>
                </div>
              </template>
            </v-range-slider>
            <seed
              v-if="selectedSection.seeds"
              :closeable="true"
              :seed="selectedSection.seeds[0]"
              class="personalized-seed"
            >
            </seed>
          </div>
        </v-col>
        <v-col
          v-if="!mobile"
          md="6"
          style="text-align: left; display: flex; align-items: center; padding: 24px;"
        >
          <div>
            <p :class="{ 'demo-title': true, mobile, desktop: !mobile }"><strong>personalize it</strong></p>
            <p :class="{ 'demo-text': true, mobile, desktop: !mobile }">
              set the tempo for each section, along with your favorite artists, songs, or genres
            </p>
          </div>
        </v-col>
      </v-row>
      <v-row :class="{ 'last-row-mobile': mobile }">
        <v-col
          v-if="(!loading && !tracks.length) || !mobile"
          md="6"
          :class="{ 'text-column': true, mobile, desktop: !mobile }"
          style="text-align: right"
        >
          <div>
            <p :class="{ 'demo-title': true, mobile, desktop: !mobile }">
              <strong>generate a playlist</strong>
            </p>
            <p :class="{ 'demo-text': true, mobile, desktop: !mobile }">
              click generate and you have a class. don't like a song? click the refresh button and get a new
              track!
            </p>
          </div>
        </v-col>
        <v-col
          md="6"
          :class="{ column: true, mobile, desktop: !mobile }"
          style="display: flex; align-items: center; justify-content: center"
        >
          <transition name="fade" mode="out-in">
            <div v-if="!loading && tracks.length" id="playlist-viewer" :class="{ mobile, desktop: !mobile }">
              <song
                @refresh="trackRefresh(track)"
                v-for="track in tracks"
                :key="track.id"
                :loading="isLoading(track)"
                :track="track"
                :drills="playlist.sections"
                :feature="getFeature(track)"
                :demo="true"
              ></song>
            </div>
          </transition>
          <v-progress-circular
            :size="120"
            :rotate="-90"
            :width="8"
            :value="calculatePercentage"
            id="loading-bar"
            v-if="loading"
            color="#ffffff"
          ></v-progress-circular>
          <div>
            <v-btn
              color="#424242"
              v-if="!loading && !tracks.length"
              @click="generate()"
              :x-large="!mobile"
              :large="mobile"
              class="text-capitalize"
              >Generate</v-btn
            >
          </div>
        </v-col>
      </v-row>
    </v-container>
    <customfooter></customfooter>
  </v-container>
</template>

<script>
import Sortable from "sortablejs"
import seed from "@/components/seed"
import song from "@/components/song"
import customfooter from "@/components/footer"
import customHeader from "@/components/header"

const firebase = require("firebase")
const _ = require("lodash")
const d3S = require("d3-scale-chromatic")
const playlist = require("@/payloads/demo.json")
const pageName = "Login"

export default {
  name: pageName,
  metaInfo: {
    title: pageName,
    titleTemplate: "%s | Cycler",
  },
  components: {
    seed,
    song,
    customfooter,
    customHeader,
  },
  async beforeCreate() {
    await this.$store.dispatch("getAppToken")
  },
  mounted() {
    this.$nextTick(() => {
      window.setInterval(() => {
        this.getApplication()
      }, 1500)
    })

    this.$store.dispatch("updateStateSimple", {
      state: "tracks",
      value: [],
    })
    this.playlist = playlist
    const container = document.getElementById("sections-container")

    if (!this.mobile) {
      const This = this
      new Sortable(container, {
        animation: 500,
        handle: ".sort-handle",
        onEnd: function(event) {
          const cloneplaylistSections = _.cloneDeep(This.playlist.sections)
          const { oldIndex, newIndex } = event
          This.playlist.sections = This.array_move(cloneplaylistSections, oldIndex, newIndex)
        },
      })
    }
    firebase.analytics().logEvent("page_view", { page_title: pageName })

    firebase.auth().onAuthStateChanged(user => {
      user ? this.login() : (this.mainButtonLoading = false)
    })
  },
  data() {
    return {
      selectedSectionId: "warm",
      playlist: {},
      features: [],
      loading: false,
      loadingTrack: null,
      mainButtonLoading: true,
      topButtonLoading: false,
      applications: [
        "yoga",
        "cycling",
        "group fitness",
        "running",
        "workouts",
        "training",
        "pilates",
        "barre",
        "bootcamp",
        "dance",
        "zumba",
        "boxing",
      ],
      displayText: "cycling",
    }
  },
  methods: {
    getApplication() {
      const possible = _.filter(this.applications, a => a != this.displayText)
      return (this.displayText = possible[Math.floor(Math.random() * possible.length)])
    },
    array_move(arr, old_index, new_index) {
      if (new_index >= arr.length) {
        var k = new_index - arr.length + 1
        while (k--) {
          arr.push(undefined)
        }
      }
      arr.splice(new_index, 0, arr.splice(old_index, 1)[0])
      return arr // for testing
    },
    isLoading(track) {
      return this.loadingTrack ? this.loadingTrack.id == track.id : false
    },
    async trackRefresh(track) {
      this.loadingTrack = track
      const trackIndex = this.tracks.indexOf(track)
      const tracks = _.cloneDeep(this.tracks)
      const section = _.find(this.playlist.sections, d => d.uuid == track.section)
      const newTrack = await this.$store.dispatch("refreshTrack", { section, tracks })
      tracks[trackIndex] = newTrack
      await this.$store.dispatch("updateStateSimple", { state: "tracks", value: tracks })
      this.features = await this.$store.dispatch("getAudioFeatures", tracks)
      this.loadingTrack = null
    },
    getFeature(track) {
      return _.find(this.features, f => f.id == track.id)
    },
    async generate() {
      this.loading = true
      await this.$store.dispatch("playlistEngine", this.playlist.sections)
      this.features = await this.$store.dispatch("getAudioFeatures", this.tracks)
      this.loading = false
    },

    seedSelected(seed) {
      const section = _.find(this.playlist.sections, ["uuid", this.selectedSectionId])
      // stub to only allow one seed per section in demo!
      section.seeds = [seed]
    },
    isSelected(section) {
      return section.uuid == this.selectedSectionId
    },
    login() {
      window.location.replace(
        `https://accounts.spotify.com/authorize?client_id=${process.env.VUE_APP_CLIENTID}&response_type=code&redirect_uri=${process.env.VUE_APP_REDIRECTURI}&scope=${this.scopes}&show_dialog=${this.showDialog}`
      )
    },
    scrollTo(id) {
      const page = document.getElementById(id)
      page.scrollIntoView({ behavior: "smooth" })
    },
    displayRange(section) {
      return `${section.range[0]} - ${section.range[1]} BPM`
    },
    createSideBorder(section, size) {
      return `${size}px solid ${this.colors[this.playlist.sections.indexOf(section)]}`
    },
  },
  computed: {
    mobile() {
      return this.$store.state.mobile
    },
    calculatePercentage() {
      let time = 0
      _.map(this.tracks, track => {
        time += track.duration_ms
      })
      const totalTime = _.clone(this.total * 60000)
      const result = time / totalTime
      return result * 100
    },
    selectedSection() {
      return this.playlist.sections ? _.find(this.playlist.sections, ["uuid", this.selectedSectionId]) : {}
    },
    tracks() {
      return this.$store.state.tracks
    },
    colors() {
      return d3S.schemeSet3
    },
    total() {
      if (this.playlist.sections) {
        return _.sum(this.playlist.sections.map(s => s.time))
      } else return "30"
    },
    scopes() {
      return this.$store.state.scopes.join(" ")
    },
    showDialog() {
      return this.$store.state.showDialog
    },
    dropdown_edit() {
      const a = new Array(56)
      return _.map(a, (x, i) => {
        const s = { text: `${i + 5} min`, value: i + 5 }
        return s
      })
    },
  },
}
</script>

<style lang="scss" scoped>
$pagePaddingSide: 16px;
#parent {
  position: relative;
  margin-top: -75px;
  margin-bottom: 75px;
}
#applicationText {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.fade-enter {
  opacity: 0;
}
.fade-enter-active {
  transition: opacity 0.5s;
}
.fade-leave-active {
  transition: opacity 0.5s;
  opacity: 0;
}
.last-row-mobile {
  padding-top: 12px;
}
.column.desktop {
  padding: 24px;
}
.column.mobile {
  padding: 8px;
  padding-right: 8px;
}
.text-column.desktop {
  display: flex;
  align-items: center;
  padding: 24px;
}
.text-column.mobile {
  display: flex;
  align-items: center;
  padding: 8px;
  padding-left: 24px;
}
.create-a-class.desktop {
  padding-top: 10px;
}
.create-a-class.mobile {
  padding-top: 0px;
}
.search-and-slider.desktop {
  padding: 24px;
}
.search-and-slider.mobile {
  padding: 24px;
  padding-top: 16px;
}
#page-container {
  padding: 0px;
}
#playlist-viewer {
  height: 210px;
  overflow-y: auto;
}
#playlist-viewer.mobile {
  margin-top: -15px;
  margin-left: 20px;
}
#playlist-viewer.desktop {
  margin-top: -15px;
}
.personalized-seed {
  margin-right: 0px;
  margin-left: 16px;
  width: auto;
}
.selectedSection {
  border-left: 4px solid white;
}
.bpm-range {
  margin: 0px !important;
  margin-top: -3px !important;
  margin-left: 18px !important;
  font-size: 18px;
  padding: 0px;
  margin-top: -6px;
  margin-left: 30px;
}
.bpm-title {
  text-align: center;
  font-size: 12px;
  margin-bottom: 0px;
}
.card-range.desktop {
  margin-left: 70px;
  font-size: 11px;
}
.card-range.mobile {
  margin-left: 5px;
  font-size: 9px;
}
.v-card {
  margin-bottom: 12px;
  text-align: left;
  cursor: pointer;
}
.card-title.desktop {
  padding-left: 20px;
  padding-bottom: 10px;
  font-size: 20px;
}
.card-title.mobile {
  padding-left: 20px;
  padding-bottom: 5px;
  font-size: 14px;
}
.minute-selecter {
  position: absolute;
  top: 6px;
  font-size: 13px;
  border: none;
  right: 25px;
  width: 78px;
  z-index: 2;
}
.sort-handle {
  opacity: 0.85;
  border: none;
  float: left;
  color: gray !important;
  font-size: 15px;
  margin-top: 22px;
  left: 10px;
}
#first-page {
  height: 100vh;
}
#second-page.desktop {
  height: 100vh;
  padding-top: 50px;
}
#second-page.mobile {
  height: 100vh;
  padding-top: 70px;
}
.demo-title.desktop {
  font-size: 34px;
}
.demo-title.mobile {
  font-size: 15px;
  padding-right: 12px;
}
.demo-text.desktop {
  font-size: 18px;
}
.demo-text.mobile {
  font-size: 12px;
  padding-right: 12px;
}
#login-with-spotify-btn {
  font-size: 20px;
}
#login-with-spotify-btn.mobile {
  font-size: 14px;
}
#spotify-icon {
  padding-right: 14px;
}
.desktop-title {
  font-size: 40px;
  padding: 220px 100px 100px 100px;
}
.mobile-title {
  font-size: 30px;
  padding: 150px 50px 60px 50px;
}
#learn-more.desktop {
  margin-top: 115px;
  // color: darken(white, 35);
  cursor: pointer;
}
#learn-more.mobile {
  margin-top: 100px;
  // color: darken(white, 35);
  cursor: pointer;
}
</style>

<template>
  <div id="container">
    <div
      v-if="!loading"
      @click="$emit('tapped')"
      :class="{ 'left-card': true, mobile, desktop: !mobile, playing: isPlaying, demo }"
      :style="{ 'border-right': findColor() }"
    >
      <img class="album-art" :src="track.album.images[0].url" />
      <div id="track-metadata">
        {{ track.name }}
        <br />
        <p id="track-artist">{{ displayArtists(track) }}</p>
      </div>
    </div>
    <div v-else :class="{ 'left-card': true, mobile, desktop: !mobile, demo }">
      <v-progress-circular :size="30" id="loading-bar" indeterminate color="#ffffff"></v-progress-circular>
    </div>
    <div :class="{ 'right-card': true, mobile, desktop: !mobile, demo }">
      <v-btn
        class="regenerate-button"
        @click="$emit('refresh')"
        color="white"
        outlined
        small
        depressed
        :disabled="loading || !sectionExists(track.section)"
        rounded
        dark
      >
        <i class="fas fa-sync-alt"></i>
      </v-btn>
      <div class="track-duration">
        <p>{{ millisToMinutesAndSeconds(track.duration_ms) }}</p>
      </div>
      <div class="bpm">
        <p>{{ displayBPM(feature) }}</p>
      </div>
    </div>
  </div>
</template>

<script>
const forEach = require("lodash/forEach")
const d3S = require("d3-scale-chromatic")

export default {
  name: "song",
  props: ["track", "feature", "loading", "drills", "demo"],
  methods: {
    displayBPM(feature) {
      if (feature) {
        const section = this.sectionExists(this.track.section)
        if (section) {
          const highTempo = section.range[1]
          let bpm = Math.round(feature.tempo)
          bpm = highTempo <= 90 && bpm > 90 ? bpm / 2 : bpm
          return bpm + " BPM"
        }
      }
    },
    sectionExists(id) {
      return _.find(this.drills, ["uuid", id])
    },
    findColor() {
      const drill = _.find(this.drills, d => d.uuid == this.track.section)
      const setting = `5px solid ${this.colors[this.drills.indexOf(drill)]}`
      return setting
    },
    displayArtists(track) {
      let result = ""
      const suffix = ", "
      const artists = track.artists
      forEach(artists, artist => {
        result += `${artist.name}${suffix}`
      })
      return result.slice(0, -suffix.length)
    },
    millisToMinutesAndSeconds(millis) {
      var minutes = Math.floor(millis / 60000)
      var seconds = ((millis % 60000) / 1000).toFixed(0)
      return minutes + ":" + (seconds < 10 ? "0" : "") + seconds
    },
  },
  computed: {
    colors() {
      return d3S.schemeSet3
    },
    mobile() {
      return this.$store.state.mobile
    },
    current() {
      return this.$store.state.current
    },
    isPlaying() {
      if (this.current) {
        if (this.current.item) {
          if (this.current.item.id == this.track.id) {
            return true
          }
        }
      }
      return false
    },
  },
}
</script>

<style lang="scss" scoped>
#container {
  display: flex;
  white-space: nowrap;
}
#loading-bar {
  margin-left: 10px;
  margin-top: 10px;
}
.album-art {
  height: 42px;
  margin: 3px;
  border-radius: 50%;
}
.regenerate-button {
  margin-top: 12px;
  margin-left: 6px;
  border: none;
}
#track-metadata {
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: left;
  color: white;
  padding-top: 11px;
  padding-left: 3px;
  margin-right: 6px;
  font-size: 11px;
}
#track-artist {
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 9px;
  color: rgb(100, 100, 100);
}
.bpm {
  color: rgb(107, 107, 107);
  font-size: 10px;
  margin-top: 20px;
  margin-left: -26px;
}
.track-duration {
  float: right;
  width: 70px;
  font-size: 11px;
  text-align: left;
  color: rgb(194, 194, 194);
  margin-left: 6px;
  margin-top: 19px;
}
.left-card {
  cursor: pointer;
  margin-bottom: 4px;
  height: 50px;
  display: flex;
  background-color: #212121;
  border-radius: 25px 0px 0px 25px;
  box-shadow: 0 3px 3px -2px #161616;
}
.left-card.desktop {
  width: 30vw;
}
.left-card.mobile {
  width: 45vw;
}
.left-card:hover {
  background-color: lighten(#212121, 5);
}
.right-card {
  display: flex;
}
.right-card.desktop {
  width: 30vw;
}
.right-card.mobile {
  width: 45vw;
}
.left-card.playing {
  border: 1px solid rgb(110, 109, 109);
  background-color: lighten(#212121, 5);
}

.left-card.demo.desktop {
  width: 200px;
}
.right-card.demo.desktop {
  width: 200px;
}
</style>

<template>
  <v-snackbar v-model="show" text :color="color" :timeout="timeout" top>
    <div>{{ message }}</div>
  </v-snackbar>
</template>

<script>
export default {
  data() {
    return {
      show: false,
      message: "",
      color: "",
      timeout: 5000,
    }
  },

  created() {
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === "snackbarFlash") {
        this.message = state.snackbar.content
        this.color = state.snackbar.color
        this.timeout = state.snackbar.timeout
        this.show = true
      }
    })
  },
}
</script>

<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="350">
      <v-card>
        <v-btn icon x-large id="sign-out-link" @click="goToSections()"><i class="fas fa-times"></i></v-btn>
        <p id="text">
          no more songs found for section <b v-if="section">{{ section.name }}</b
          >. try editing the section criteria via the button below
        </p>

        <v-card-actions>
          <v-container fluid>
            <v-btn
              id="action-btn"
              class="text-lowercase"
              rounded
              x-large
              color="#242424"
              dark
              @click="editSection()"
            >
              edit
            </v-btn>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: "empty",
  props: ["open", "section"],
  data() {
    return {
      dialog: false,
    }
  },
  methods: {
    editSection() {
      this.$router.push(`sections/${this.section.uuid}`)
    },
    goToSections() {
      this.$router.push("sections")
    },
  },
  watch: {
    open() {
      this.dialog = this.open
    },
  },
  computed: {},
}
</script>

<style lang="scss" scoped>
#action-btn {
  padding: 20px;
  margin-top: -10px;
}
#sign-out-link {
  position: absolute;
  right: 6px;
  top: 6px;
}
#text {
  padding: 60px 30px 0px 30px;
}
</style>

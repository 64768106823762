<template>
  <v-container fluid id="page">
    <customHeader :clickable="true"></customHeader>
    <div id="sub-container">
      <h1>Hi! How can we help?</h1>
      <v-text-field placeholder="search here..."></v-text-field>
      <br />
      <h2>What is Cycler?</h2>
      <p>
        Cycler uses Spotify to enable fitness instructors to create faster, better playlists on mobile or
        desktop. Create a new class and add sections. Each section can be customized for BPM and time and
        inspired by up to five hand-picked genres, artists, or songs. Press generate and your new playlist
        awaits. Give each song a preview and press the refresh button to find different tracks that fit your
        criteria.
      </p>
      <br />
      <h2>Getting Started</h2>
      <p>
        <a target="_blank" href="https://cycler.io">Login</a> to Cycler.io to get started creating classes!
        You will be prompted to login with your Spotify username and password.
      </p>
      <br />
      <h2>Defining Sections</h2>
      <p>
        Sections help you control how your playlist sounds and feels. Search for songs, artists, and genres to
        define the mood of your sections. Create, rearrange, and revise sections to perfect your playlist.
      </p>
      <br />
      <h2>Creating Playlists</h2>
      <p>
        Just click "Generate" to create a playlist from your sections. Every Cycler playlist is mixable and
        sounds like a DJ made it. Don't like a song? Click refresh!
      </p>
      <br />
      <h2>Saving Classes</h2>
      <p>You can save playlists to Spotify once you're happy with it.</p>
      <br />
      <h2>Do you have an Instagram?</h2>
      <p>Absolutely <a target="_blank" href="http://instagram.com/cycler.io">we do</a></p>
      <br />
      <h2>Reporting Issues</h2>
      <p>
        You can report issues with Cycler with
        <a target="_blank" href="https://airtable.com/shrlsi8RVn6bSuoKO">this form</a>
      </p>
      <br />
    </div>
  </v-container>
</template>

<script>
import customHeader from "@/components/header"
const firebase = require("firebase")
const pageName = "Support"
export default {
  name: pageName,
  metaInfo: {
    title: pageName,
    titleTemplate: "%s | Cycler",
  },
  components: {
    customHeader,
  },
  mounted() {
    firebase.analytics().logEvent("page_view", { page_title: pageName })
    const page = document.getElementById("page")
    page.scrollIntoView({ behavior: "smooth" })
  },
  computed: {
    user() {
      return this.$store.state.user
    },
  },
}
</script>

<style lang="scss" scoped>
#sub-container {
  text-align: left;
  margin-top: 80px;
  margin-left: 5%;
  margin-right: 5%;
}
</style>

<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="350">
      <v-card>
        <a v-if="persistant" id="sign-out-link" @click="logout()">sign out</a>
        <v-btn v-else icon x-large id="sign-out-link" @click="$emit('closed')"
          ><i class="fas fa-times"></i
        ></v-btn>

        <v-container fluid>
          <img id="logo" src="@/assets/app-logo.png" />
          <p>
            subscribe to cycler!
          </p>
        </v-container>
        <v-btn
          :id="selectedPrice == monthlyPrice ? 'selected-btn' : 'unselected-btn'"
          @click="selectedPrice = monthlyPrice"
          class="price-btn text-lowercase"
          outlined
          height="120"
          width="120"
          ><div class="price-info">
            <b class="price">$8.99</b>
            <p>/ month</p>
          </div>
        </v-btn>
        <v-btn
          :id="selectedPrice == yearlyPrice ? 'selected-btn' : 'unselected-btn'"
          @click="selectedPrice = yearlyPrice"
          class="price-btn text-lowercase"
          outlined
          height="120"
          width="120"
          ><div class="price-info">
            <b class="price">$89</b>
            <p>/ year</p>
          </div></v-btn
        >
        <v-card-actions>
          <v-container fluid>
            <v-btn
              id="start-trial-btn"
              class="text-lowercase"
              rounded
              x-large
              color="#242424"
              dark
              :loading="loading"
              @click="checkout()"
            >
              try free for 30 days
            </v-btn>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { loadStripe } from "@stripe/stripe-js"
import stripe from "@/store/stripe"

export default {
  name: "checkout",
  props: ["open", "persistant"],
  created() {
    loadStripe(process.env.VUE_APP_STRIPEPUBLICKEY).then(stripe => {
      this.stripe = stripe
    })
  },
  async mounted() {
    this.monthlyPrice = process.env.VUE_APP_STRIPEMONTHLY
    this.yearlyPrice = process.env.VUE_APP_STRIPEYEARLY
    this.selectedPrice = this.monthlyPrice

    // attempt to find the customer on stripe via their spotify email
    const customers = await stripe.getCustomer(this.user.email)
    if (customers.length) this.customer = customers[0].id
  },
  data() {
    return {
      dialog: false,
      monthlyPrice: null,
      yearlyPrice: null,
      selectedPrice: null,
      loading: false,
      stripe: null,
      customer: null,
    }
  },
  methods: {
    async checkout() {
      this.loading = true
      const response = await fetch(process.env.VUE_APP_API + "/createCheckout", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          priceId: this.selectedPrice,
          customerId: this.customer,
          clientReferenceId: this.user.uid,
          successUrl: window.location.href,
          cancelUrl: window.location.href,
        }),
      })
      const data = await response.json()
      this.stripe.redirectToCheckout({
        sessionId: data.sessionId,
      })
    },
    logout() {
      // logout of firebase
      this.$store.dispatch("logoutFirebase").then(() => {
        this.$store.dispatch("logoutSpotify").then(() => {
          this.$router.push("/")
        })
      })
    },
  },
  watch: {
    open() {
      this.dialog = this.open
    },
  },
  computed: {
    user() {
      return this.$store.state.user
    },
  },
}
</script>

<style lang="scss" scoped>
#start-trial-btn {
  padding: 20px;
}
.price-btn {
  margin: 0px 6px;
}
#selected-btn {
  background-color: #858585;
}
#unselected-btn {
  color: #242424;
}
.price-info {
  margin-top: 12px;
}
.price {
  font-size: 24px;
}
.title {
  margin-top: 12px;
  // font-size: 24px !important;
}
#logo {
  height: 60px;
  margin-top: 12px;
}
#sign-out-link {
  position: absolute;
  right: 9px;
  top: 9px;
  font-size: 12px;
  text-decoration: none;
}
</style>

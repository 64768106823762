<template>
  <v-app id="app">
    <transition name="fade" mode="out-in">
      <router-view name="main" :key="currentRouteName"></router-view>
    </transition>
    <snackbar />
  </v-app>
</template>

<script>
import Vue from "vue"
import snackbar from "@/components/snackbar.vue"
export default Vue.extend({
  components: {
    snackbar,
  },
  mounted() {
    this.setMobile() // sets mobile
    window.addEventListener("resize", this.setMobile)
  },
  computed: {
    currentRouteName() {
      return this.$route.name
    },
  },
  methods: {
    setMobile() {
      this.$store.dispatch("updateStateSimple", {
        state: "mobile",
        value: window.innerWidth <= 600,
      })
    },
  },
  name: "App",
})
</script>

<style lang="scss">
$transition: 0.2s;

#app {
  font-family: "Comfortaa", cursive;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  background-color: #1c1c1c;
}
.fade-enter {
  opacity: 0;
}
.fade-enter-active {
  transition: opacity $transition;
}
.fade-leave-active {
  transition: opacity $transition;
  opacity: 0;
}
</style>

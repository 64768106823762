<template>
  <div>
    <v-toolbar color="secondary" height="65px;" id="top-toolbar">
      <v-container fluid>
        <v-btn id="menu-button" @click="$router.push('account')" rounded outlined x-large>
          <i class="fas fa-user-alt"></i>
        </v-btn>
        <div id="search-bar">
          <v-text-field
            v-model="search"
            placeholder="search classes..."
            background-color="#272727"
            rounded
            flat
            solo
          ></v-text-field>
        </div>
        <v-btn @click="showEditor = true" id="add-class-button" rounded outlined depressed x-large dark>
          <i class="fas fa-plus"></i>
        </v-btn>
      </v-container>
    </v-toolbar>
    <v-container fluid class="home-container">
      <div v-for="(c, i) in playlists" :key="i + c.name">
        <div class="v-card">
          <v-card
            color="secondary"
            :hover="true"
            @click="$router.push(`/playlists/${c.uuid}/sections`)"
            class="actual-card"
            dark
          >
            <v-card-title class="class-name">{{ c.name }}</v-card-title>
            <v-card-subtitle class="class-length">{{ classLength(c) }}:00</v-card-subtitle>
          </v-card>
          <v-menu bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-if="!showEditor"
                class="ellipsis"
                v-on="on"
                v-bind="attrs"
                rounded
                depressed
                outlined
                dark
              >
                <i class="fas fa-ellipsis-v"></i>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="duplicateClass(c)">Copy</v-list-item>
              <v-list-item @click="deleteClass(c)">Delete</v-list-item>
            </v-list>
          </v-menu>
        </div>
      </div>
    </v-container>
    <transition name="fade" mode="in-out">
      <editor
        @close="showEditor = false"
        @save="createClass"
        :confirmation="'Create Class'"
        :placeholder="'add class name...'"
        v-if="showEditor"
      ></editor>
    </transition>
  </div>
</template>

<script>
const _ = require("lodash")
import editor from "@/components/editor"
import payloads from "../payloads"
const uuid = require("uuid/v1")
const firebase = require("firebase")
const pageName = "Home"

export default {
  name: pageName,
  metaInfo: {
    title: pageName,
    titleTemplate: "%s | Cycler",
  },
  components: {
    editor,
  },
  data() {
    return {
      search: "",
      showEditor: false,
    }
  },
  mounted() {
    this.$store.dispatch("getGenres")
    firebase.analytics().logEvent("page_view", { page_title: pageName })
  },
  methods: {
    deleteClass(c) {
      this.$store.dispatch("deleteClass", c.uuid).then(() => {})
    },
    duplicateClass(c) {
      const clone = _.cloneDeep(c)
      delete clone.playlist
      clone.uuid = uuid()
      // delete clone.tracks;
      this.$store.dispatch("duplicateClass", clone).then(() => {})
    },
    createClass(className) {
      let payload = _.cloneDeep(payloads.playlist)
      payload.uuid = uuid()
      payload.name = className

      this.$store.dispatch("setClass", payload).then(() => {
        this.$router.push(`/playlists/${payload.uuid}/sections`)
      })
    },
    classLength(c) {
      return _.sum(c.sections.map(d => d.time))
    },
  },
  computed: {
    playlists() {
      let pl = this.$store.state.playlists
      return !this.search ? pl : _.filter(pl, p => p.name.toLowerCase().includes(this.search.toLowerCase()))
    },
  },
}
</script>

<style lang="scss" scoped>
.fade-enter {
  opacity: 0;
}
.fade-enter-active {
  transition: opacity 0.15s;
}
.fade-leave-active {
  transition: opacity 0.15s;
  opacity: 0;
}
v-list-item {
  cursor: pointer;
}
#search-bar {
  width: 350px;
  margin-top: 32px;
  margin-left: 70px;
}
#menu-button {
  position: absolute;
  top: 7px;
  margin: 0px;
  border: none;
  left: 6px;
  margin-top: 1px;
}
.adder {
  margin-right: 12px;
  margin-bottom: 1px;
}
#add-class-button {
  position: absolute;
  top: 7px;
  margin: 0px;
  border: none;
  right: 6px;
  margin-top: 1px;
}
.center-button-vertically {
  position: absolute;
  top: 40vh;
}
#player {
  position: fixed;
  z-index: 5;
  bottom: 0px;
}
.ellipsis {
  border: none;
  position: relative;
  float: right;
  font-size: 16px;
  right: 12px;
  color: gray !important;
  top: -78px;
}

.class-length {
  font-size: 12px;
  color: gray !important;
  position: absolute;
  left: 0px;
}
.home-container {
  -webkit-overflow-scrolling: touch;
  overflow: scroll;
  width: 90vw;
  margin-top: 70px;
  padding-top: 45px;
  left: 5vw;
}
#top-toolbar {
  position: fixed;
  width: 100vw;
  z-index: 1;
}
.v-card {
  position: relative;
  width: 100%;
  height: 85px;
  margin-bottom: 18px;
  white-space: nowrap !important;
}
.actual-card {
  white-space: nowrap !important;
}
.class-name {
  font-size: 16px;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  margin-right: 70px;
}
</style>

import Vue from "vue"
import Router from "vue-router"

import Login from "../views/Login.vue"
import Authorize from "../views/Authorize.vue"
import Home from "../views/Home.vue"
import Account from "../views/Account.vue"
import Subscription from "../views/Subscription.vue"
import Section from "../views/Section.vue"
import Sections from "../views/Sections.vue"
import Tracks from "../views/Tracks.vue"
import Terms from "../views/Terms.vue"
import Privacy from "../views/Privacy.vue"
import Support from "../views/Support.vue"

import store from "../store"

Vue.use(Router)

export default new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "login",
      components: {
        main: Login,
      },
    },
    {
      path: "/authorize",
      name: "authorize",
      components: {
        main: Authorize,
      },
    },
    {
      path: "/home",
      name: "home",
      components: {
        main: Home,
      },
      beforeEnter(to, from, next) {
        store.getters["isAuthenticated"] ? next() : next({ name: "login" })
      },
    },
    {
      path: "/account",
      name: "account",
      components: {
        main: Account,
      },
      beforeEnter(to, from, next) {
        store.getters["isAuthenticated"] ? next() : next({ name: "login" })
      },
    },
    {
      path: "/subscription",
      name: "subscription",
      components: {
        main: Subscription,
      },
      beforeEnter(to, from, next) {
        store.getters["isAuthenticated"] ? next() : next({ name: "login" })
      },
    },
    {
      path: "/playlists/:uuid/sections/:id",
      name: "section",
      components: {
        main: Section,
      },
      beforeEnter(to, from, next) {
        store.getters["isAuthenticated"] ? next() : next({ name: "login" })
      },
    },
    {
      path: "/playlists/:uuid/sections",
      name: "sections",
      components: {
        main: Sections,
      },
      beforeEnter(to, from, next) {
        store.getters["isAuthenticated"] ? next() : next({ name: "login" })
      },
    },
    {
      path: "/playlists/:uuid/tracks",
      name: "tracks",
      components: {
        main: Tracks,
      },
      beforeEnter(to, from, next) {
        store.getters["isAuthenticated"] ? next() : next({ name: "login" })
      },
    },
    {
      path: "/privacy",
      name: "privacy",
      components: {
        main: Privacy,
      },
    },
    {
      path: "/terms",
      name: "terms",
      components: {
        main: Terms,
      },
    },
    {
      path: "/support",
      name: "support",
      components: {
        main: Support,
      },
    },
  ],
})

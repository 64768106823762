<template>
  <v-dialog persistent id="dialog" v-model="dialog" fullscreen transition="dialog-top-transition">
    <v-card class="mx-auto" color="info">
      <div>
        <v-btn id="back-btn" @click="$router.push('home')" rounded outlined x-large>
          <i class="fa fa-arrow-left" aria-hidden="true"></i>
        </v-btn>
      </div>
      <v-card-title style="padding-bottom: 12px; padding-top: 24px; display: flex; justify-content: center">
        {{ formattedName }}
      </v-card-title>
      <v-expansion-panels>
        <v-expansion-panel readonly>
          <v-expansion-panel-header
            expand-icon="mdi-chevron-right"
            @click="$router.push('subscription')"
            class="expansion-header"
            style="padding-top: 0px; padding-bottom: 0px"
          >
            subscription
            <div>
              <v-chip style="border: none" outlined v-if="user.subscription" class="ma-2" color="success">
                active
              </v-chip>
              <v-chip v-else style="border: none" outlined class="ma-2" color="grey">
                inactive
              </v-chip>
            </div>
          </v-expansion-panel-header>
        </v-expansion-panel>
        <v-expansion-panel readonly>
          <v-expansion-panel-header
            expand-icon="mdi-chevron-right"
            @click="$router.push('terms')"
            class="expansion-header"
          >
            terms
          </v-expansion-panel-header>
        </v-expansion-panel>
        <v-expansion-panel readonly>
          <v-expansion-panel-header
            expand-icon="mdi-chevron-right"
            @click="$router.push('privacy')"
            class="expansion-header"
          >
            privacy
          </v-expansion-panel-header>
        </v-expansion-panel>
        <v-expansion-panel readonly>
          <v-expansion-panel-header
            expand-icon="mdi-chevron-right"
            @click="$router.push('support')"
            class="expansion-header"
          >
            support
          </v-expansion-panel-header>
        </v-expansion-panel>
      </v-expansion-panels>
      <v-btn rounded outlined id="logout-btn" class="text-lowercase" @click="logout()">
        logout
      </v-btn>
    </v-card>
    <customFooter></customFooter>
  </v-dialog>
</template>

<script>
import stripe from "@/store/stripe"
import customFooter from "@/components/footer"
const firebase = require("firebase")
const pageName = "Account"

export default {
  name: pageName,
  metaInfo: {
    title: pageName,
    titleTemplate: "%s | Cycler",
  },
  components: {
    customFooter,
  },
  data() {
    return {
      dialog: true,
      loading: false,
      stripe: null,
      subscription: null,
    }
  },
  mounted() {
    firebase.analytics().logEvent("page_view", { page_title: pageName })
    if (this.user.subscription) {
      this.getSubscription(this.user.subscription)
    }
  },
  methods: {
    async getSubscription(id) {
      const subscription = await stripe.getSubscription(id)
      this.subscription = subscription
    },
    logout() {
      // logout of firebase
      this.$store.dispatch("logoutFirebase").then(() => {
        this.$store.dispatch("logoutSpotify").then(() => {
          this.$router.push("/")
        })
      })
    },
  },
  computed: {
    user() {
      return this.$store.state.user
    },
    formattedName() {
      return this.user.display_name.split(" ")[0]
    },
  },
}
</script>

<style lang="scss" scoped>
#back-btn {
  position: fixed;
  top: 7px;
  left: 7px;
  opacity: 0.85;
  border: none;
}
#logout-btn {
  position: fixed;
  top: 20px;
  right: 12px;
}
.expansion-header {
  background-color: #1c1c1c;
}
.expansion-content {
  border-top: 1px solid lighten(#1c1c1c, 6);
  background-color: #1c1c1c;
}
</style>

require("dotenv").config() // load environment variables from .env file

import Vue from "vue"
import App from "./App.vue"
import router from "./router"
import store from "./store"

import vuetify from "./plugins/vuetify"
import snackbarPlugin from "./plugins/snackbar"
import VueMeta from "vue-meta"

Vue.use(VueMeta)
Vue.use(snackbarPlugin, { store })
Vue.config.productionTip = false

Vue.directive("blur", {
  inserted: function(el) {
    el.onfocus = ev => ev.target.blur()
  },
})

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App),
}).$mount("#app")

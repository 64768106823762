import Vue from "vue"
import Vuex from "vuex"

import Spotify from "./spotify" // spotify library for API calls
import "./firebase" // initiates firebase

const _ = require("lodash")
const firebase = require("firebase")

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    intervalId: null,
    showDialog: false,
    playlists: [],
    accessToken: null,
    refreshToken: null,
    user: null,
    appToken: null,
    snackbar: {},
    mobile: false,
    current: null,
    features: null,
    tracks: [],
    genres: [],
    device: null,
    scopes: [
      "playlist-modify-public",
      "user-read-email",
      "user-read-private",
      "user-read-playback-state",
      "user-modify-playback-state",
      "user-read-currently-playing",
      "app-remote-control",
      "streaming",
    ],
  },
  getters: {
    isAuthenticated: state => {
      return !!state.accessToken
    },
  },
  mutations: {
    updateStateSimple(state, obj) {
      state[obj.state] = obj.value
    },
    setTokens(state, tokens) {
      state.accessToken = tokens.access_token
      state.refreshToken = tokens.refresh_token
    },
    snackbarFlash(state, payload) {
      state.snackbar = payload
    },
  },
  actions: {
    playlistsListener: context => {
      return new Promise((resolve, reject) => {
        firebase
          .firestore()
          .collection("settings")
          .doc(context.state.user.uid)
          .collection("playlists")
          .onSnapshot(querySnapshot => {
            const data = querySnapshot.docs.map(doc => doc.data())
            context.commit("updateStateSimple", {
              state: "playlists",
              value: data,
            })
            resolve(data)
          })
      })
    },
    userListener: context => {
      return new Promise((resolve, reject) => {
        firebase
          .firestore()
          .collection("users")
          .doc(context.state.user.uid)
          .onSnapshot(doc => {
            const data = doc.data()
            context.commit("updateStateSimple", {
              state: "user",
              value: data,
            })
            resolve(data)
          })
      })
    },
    setClass: (context, payload) => {
      return new Promise((resolve, reject) => {
        firebase
          .firestore()
          .collection(`settings/${context.state.user.uid}/playlists`)
          .doc(payload.uuid)
          .set(payload)
          .then(result => resolve(result))
          .catch(error => reject(error))
      })
    },
    duplicateClass: (context, c) => {
      return new Promise((resolve, reject) => {
        const clone = _.cloneDeep(c)
        clone.name = c.name + " Copy"
        firebase
          .firestore()
          .collection(`settings/${context.state.user.uid}/playlists`)
          .doc(`${clone.uuid}`)
          .set(clone)
          .then(result => resolve(result))
          .catch(error => reject(error))
      })
    },
    updateClass: (context, payload) => {
      return new Promise((resolve, reject) => {
        firebase
          .firestore()
          .collection(`settings/${context.state.user.uid}/playlists`)
          .doc(`${payload.uuid}`)
          .update(payload)
          .then(result => resolve(result))
          .catch(error => reject(error))
      })
    },
    deleteClass: (context, id) => {
      return new Promise((resolve, reject) => {
        firebase
          .firestore()
          .collection(`settings/${context.state.user.uid}/playlists`)
          .doc(`${id}`)
          .delete()
          .then(() => resolve())
          .catch(error => reject(error))
      })
    },
    refreshTrack: (context, data) => {
      return new Promise((resolve, reject) => {
        const { section, tracks } = data
        Spotify.findPlaylistTracks(section, tracks)
          .then(results => {
            resolve(results[0])
          })
          .catch(e => {
            reject(e)
          })
      })
    },
    getCurrentlyPlaying: context => {
      return new Promise((resolve, reject) => {
        Spotify.getCurrentlyPlaying().then(current => {
          context.commit("updateStateSimple", {
            state: "current",
            value: current,
          })
          resolve(current)
        })
      })
    },
    turnOffShuffle: context => {
      return new Promise((resolve, reject) => {
        Spotify.turnOffShuffle()
          .then(current => {
            resolve(current)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    getGenres: context => {
      return new Promise((resolve, reject) => {
        Spotify.getGrenres().then(result => {
          const genres = result.map(r => {
            return {
              id: r,
              name: r,
              type: "genre",
            }
          })
          context.commit("updateStateSimple", {
            state: "genres",
            value: genres,
          })
          resolve(genres)
        })
      })
    },
    playPlayer: context => {
      return new Promise((resolve, reject) => {
        const id = context.state.device.id
        Spotify.playPlayer(id)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    pausePlayer: context => {
      return new Promise((resolve, reject) => {
        const id = context.state.device.id
        Spotify.pausePlayer(id)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    seekPlayer: (context, time) => {
      return new Promise((resolve, reject) => {
        const id = context.state.device.id
        Spotify.seek(time, id)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    nextPlayer: context => {
      return new Promise((resolve, reject) => {
        const id = context.state.device.id
        Spotify.nextPlayer(id)
          .then(() => {
            resolve()
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    getTracks: (context, ids) => {
      return new Promise((resolve, reject) => {
        Spotify.getTracks(ids)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    getAnalysis: (context, track) => {
      return new Promise((resolve, reject) => {
        Spotify.getAnalysis(track)
          .then(analysis => {
            resolve(analysis)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    playTracks: (context, uris) => {
      return new Promise((resolve, reject) => {
        const id = context.state.device.id
        Spotify.playTracks(id, uris)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    getActiveDevice: context => {
      return new Promise((resolve, reject) => {
        Spotify.getDevices()
          .then(devices => {
            let activeDevice = _.find(devices, d => d.is_active) || devices[0]
            context.commit("updateStateSimple", {
              state: "device",
              value: activeDevice,
            })
            resolve()
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    updateStateSimple: (context, obj) => {
      return context.commit("updateStateSimple", obj)
    },
    logoutFirebase: context => {
      return new Promise((resolve, reject) => {
        firebase
          .auth()
          .signOut()
          .then(() => {
            resolve()
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    logoutSpotify: context => {
      return new Promise((resolve, reject) => {
        context.commit("updateStateSimple", {
          state: "showDialog",
          value: true,
        })
        resolve()
      })
    },
    getUser: context => {
      return new Promise((resolve, reject) => {
        Spotify.getUser()
          .then(user => {
            resolve(user)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    setUser: (context, user) => {
      return new Promise((resolve, reject) => {
        const userRef = firebase
          .firestore()
          .collection("users")
          .doc(user.uid)
        userRef.get().then(snapshot => {
          if (snapshot.exists) {
            userRef.update(user)
          } else {
            userRef.set(user)
          }
          context.commit("updateStateSimple", {
            state: "user",
            value: user,
          })
          resolve()
        })
      })
    },
    getAudioFeature: (context, id) => {
      return new Promise((resolve, reject) => {
        Spotify.getAudioFeature(id)
          .then(feature => {
            resolve(feature)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    getAudioFeatures: (context, tracks) => {
      return new Promise((resolve, reject) => {
        Spotify.getAudioFeatures(tracks)
          .then(features => {
            context.commit("updateStateSimple", {
              state: "features",
              value: features,
            })
            resolve(features)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    createPlaylist: (context, name) => {
      return new Promise((resolve, reject) => {
        Spotify.createPlaylist(context.state.user.id, name)
          .then(result => {
            resolve(result.id)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    savePlaylist: (context, data) => {
      const { tracks, id } = data
      return new Promise((resolve, reject) => {
        Spotify.savePlaylist(tracks, id)
          .then(result => {
            resolve(result)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    searchTracksAndArtists: (context, keyword) => {
      return new Promise((resolve, reject) => {
        Spotify.searchTracksAndArtists(keyword)
          .then(results => {
            resolve(results)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    getAppToken: context => {
      return new Promise((resolve, reject) => {
        Spotify.getAppToken()
          .then(token => {
            context.commit("updateStateSimple", { state: "appToken", value: token.access_token })
            resolve(token)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    getTokens: (context, payload) => {
      return new Promise((resolve, reject) => {
        Spotify.getTokens(payload)
          .then(tokens => {
            resolve(context.commit("setTokens", tokens))
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    refreshTokens: (context, refreshToken) => {
      return new Promise((resolve, reject) => {
        Spotify.refreshTokens(refreshToken)
          .then(tokens => {
            if (!tokens.refresh_token) {
              tokens.refresh_token = context.state.refreshToken
            }
            resolve(context.commit("setTokens", tokens))
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    playlistEngine: async (context, sections) => {
      // an engine to create playlists
      const counter = 1
      const playlist = []
      const section = sections[0]
      const remainingSectionTime = section.time * 60000 // milliseconds

      await playlistEngine(section, remainingSectionTime, playlist, counter, sections, context)
    },
  },
})

function playlistEngine(section, time, playlist, counter, sections, context) {
  return new Promise((resolve, reject) => {
    addTracksToPlaylist(section, time, playlist)
      .then(function(result) {
        let [time, playlist] = result
        context.dispatch("updateStateSimple", { state: "tracks", value: playlist })
        if (time > 20000 * sections.length)
          resolve(playlistEngine(section, time, playlist, counter, sections, context))
        else {
          // move to the next section
          counter++
          if (counter <= sections.length) {
            const section = sections[counter - 1]
            time = section.time * 60000
            resolve(playlistEngine(section, time, playlist, counter, sections, context))
          } else {
            resolve(playlist)
          }
        }
      })
      .catch(e => {
        reject(e)
      })
  })
}

async function addTracksToPlaylist(section, time, tracks) {
  let returnedTracks = await Spotify.findPlaylistTracks(section, tracks)
  returnedTracks.forEach(t => (time -= t.duration_ms))
  tracks.push(...returnedTracks)
  return [time, tracks]
}

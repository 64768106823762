<template>
  <div>
    <v-toolbar height="65px;" id="top-toolbar">
      <v-container fluid>
        <v-btn
          @click="$router.push('/home')"
          class="action-button-styling"
          color="white"
          id="home-button"
          rounded
          x-large
          depressed
          outlined
          dark
        >
          <i class="fa fa-arrow-left" aria-hidden="true"></i>
        </v-btn>
        <v-btn
          @click="createSection"
          class="action-button-styling"
          color="white"
          id="add-btn"
          rounded
          x-large
          depressed
          outlined
          dark
        >
          <i class="fas fa-plus"></i>
        </v-btn>
        <div class="title-holder" @click="showEditor = true">
          <p id="title">{{ playlist.name }}</p>
        </div>
      </v-container>
    </v-toolbar>
    <v-container fluid>
      <div>
        <v-container fluid id="playlist-length" v-if="playlist.sections">
          <p>{{ time }}:00</p>
        </v-container>
        <div id="class-container">
          <div id="section-container">
            <div class="v-card" v-for="(section, index) in playlist.sections" :key="section.uuid + index">
              <v-card
                :hover="true"
                :style="{ 'border-right': createSideBorder(section, 8) }"
                @click="$router.push(`sections/${section.uuid}`)"
              >
                <v-btn
                  class="reorder-button action-button-styling"
                  color="grey"
                  depressed
                  outlined
                  rounded
                  dark
                >
                  <i class="fas fa-bars"></i>
                </v-btn>
                <v-card-title class="section-name">{{ section.name }}</v-card-title>
                <v-card-subtitle class="section-length">{{ getRange(section) }}</v-card-subtitle>
              </v-card>
              <v-menu bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="action-button-styling options"
                    v-on="on"
                    v-bind="attrs"
                    rounded
                    depressed
                    outlined
                    dark
                  >
                    <i class="fas fa-ellipsis-v"></i>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item @click="dupliate(index)">Copy</v-list-item>
                  <v-list-item @click="remove(index)">Delete</v-list-item>
                </v-list>
              </v-menu>
              <v-select
                v-model="playlist.sections[index].time"
                class="minute-selecter"
                item-value="value"
                :items="dropdown"
                menu-props="auto"
                hide-details
                label="Select"
                single-line
              ></v-select>
            </div>
          </div>
        </div>
        <v-btn
          v-if="playlist.sections"
          @click="$router.push(`tracks`)"
          id="generate-btn"
          :class="{ 'text-capitalize': true, mobile, desktop: !mobile }"
          rounded
          x-large
          elevation="5"
          color="secondary"
          dark
        >
          <!-- <i id="music-icon" class="fa fa-music"></i> -->
          {{ playlist.playlist ? "View Playlist" : "Generate Playlist" }}
        </v-btn>
      </div>
      <transition name="fade" mode="out-in">
        <editor
          confirmation="Save Playlist"
          @close="showEditor = false"
          :current="playlist.name"
          @save="saveName"
          v-if="showEditor"
        ></editor>
      </transition>
    </v-container>
  </div>
</template>

<script>
const _ = require("lodash")
const d3S = require("d3-scale-chromatic")
const uuid = require("uuid/v1")
const firebase = require("firebase")
const pageName = "Sections"

import editor from "@/components/editor"
import Sortable from "sortablejs"

export default {
  name: pageName,
  metaInfo: {
    title: pageName,
    titleTemplate: "%s | Cycler",
  },
  components: {
    editor,
  },
  data() {
    return {
      playlist: {},
      showEditor: false,
    }
  },
  mounted() {
    firebase.analytics().logEvent("page_view", { page_title: pageName })
    // to accomodate the async nature of url params
    this.$nextTick(() => {
      this.playlist = _.find(this.playlists, ["uuid", this.uuid])
      if (this.playlist && this.playlist.sections) {
        if (!this.playlist.sections.length) this.createSection()
        else this.initSortable()
      } else {
        this.$router.push("/home")
      }
    })
  },
  methods: {
    moveIndex(arr, oldIndex, newIndex) {
      if (newIndex >= arr.length) {
        var k = newIndex - arr.length + 1
        while (k--) {
          arr.push(undefined)
        }
      }
      arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0])
      return arr
    },
    createSideBorder(section, size) {
      return `${size}px solid ${this.colors[this.playlist.sections.indexOf(section)]}`
    },
    initSortable() {
      const This = this
      this.$nextTick(() => {
        const container = document.getElementById("section-container")
        new Sortable(container, {
          animation: 500,
          handle: ".reorder-button",
          onEnd: function(event) {
            const { oldIndex, newIndex } = event
            This.playlist.sections = This.moveIndex(This.playlist.sections, oldIndex, newIndex)
          },
        })
      })
    },
    createSection() {
      const newUuid = uuid()
      this.$router.push(`sections/${newUuid}`)
    },
    saveName(name) {
      this.playlist.name = name
      this.showEditor = false
    },
    getRange(section) {
      return `${section.range[0]} - ${section.range[1]} BPM`
    },
    remove(index) {
      this.playlist.sections.splice(index, 1)
    },
    dupliate(index) {
      const section = _.cloneDeep(this.playlist.sections[index])
      section.name = section.name + " copy"
      section.uuid = uuid()
      this.playlist.sections.splice(index + 1, 0, section)
    },
  },
  computed: {
    mobile() {
      return this.$store.state.mobile
    },
    dropdown() {
      const a = new Array(56)
      return _.map(a, (x, i) => {
        const s = { text: `${i + 5} min`, value: i + 5 }
        return s
      })
    },
    uuid() {
      return this.$route.params.uuid
    },
    time() {
      let time = 0
      this.playlist.sections.map(d => (time += d.time))
      return time
    },
    colors() {
      return d3S.schemeSet3
    },
    playlists() {
      return this.$store.state.playlists
    },
  },
  watch: {
    playlist: {
      deep: true,
      handler() {
        const uuid = this.uuid
        const sections = this.playlist.sections
        const payload = { sections, uuid }
        this.$store.dispatch("updateClass", payload)
      },
    },
  },
}
</script>

<style lang="scss" scoped>
$toolbar: #272727;
$light: #cccccc;
.fade-enter {
  opacity: 0;
}
.fade-enter-active {
  transition: opacity 0.15s;
}
.fade-leave-active {
  transition: opacity 0.15s;
  opacity: 0;
}
#music-icon {
  margin-right: 12px;
}
.title-holder {
  white-space: nowrap;
  position: absolute;
  width: 50vw;
  left: 25vw;
  top: -2px;
}
#title {
  font-size: 22px;
  font-style: bold;
  color: white;
  z-index: 3;
  margin-top: 20px;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
}
#generate-btn {
  position: fixed;
  bottom: 40px;
}
#generate-btn.mobile {
  width: 70vw;
  left: 15vw;
}
#generate-btn.desktop {
  width: 50vw;
  left: 25vw;
}

#top-toolbar {
  position: fixed;
  width: 100vw;
  background-color: $toolbar;
}
.options {
  float: right;
  z-index: 10;
  top: -80px;
  color: gray !important;
  font-size: 15px;
  right: 10px;
}
.reorder-button {
  float: left;
  color: gray !important;
  font-size: 15px;
  margin-top: 22px;
  left: 5px;
}

#playlist-length {
  position: fixed;
  top: 68px;
  right: 0px;
  color: $light;
}
.action-button-styling {
  opacity: 0.85;
  border: none;
}
.v-card {
  background-color: $toolbar;
  position: relative;
  width: 100%;
  height: 85px;
  margin-bottom: 18px;
  cursor: pointer;
}
#home-button {
  position: fixed;
  left: 7px;
  top: 7px;
}
#add-btn {
  position: fixed;
  right: 7px;
  top: 7px;
}
input:focus {
  outline: none;
}
#class-container {
  position: fixed;
  margin-top: 70px;
  left: 5vw;
  top: 45px;
  width: 90vw;
  bottom: 115px;
  overflow-y: auto;
}

.section-name {
  font-size: 15px;
  position: absolute;
  left: 58px;
  top: 1px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.section-length {
  font-size: 10px;
  position: absolute;
  color: gray !important;
  left: 58px;
  top: 59px;
}
.minute-selecter {
  position: absolute;
  top: 9px;
  font-size: 13px;
  border: none;
  right: 80px;
  width: 78px;
  z-index: 2;
}
</style>

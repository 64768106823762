<template>
  <v-container fluid>
    <!-- <img id="title" src="@/assets/logo.png" /> -->
    <p id="title-text">cycler</p>
    <v-progress-circular
      :size="40"
      indeterminate
      :width="4"
      id="loading-bar"
      color="#ffffff"
    ></v-progress-circular>
  </v-container>
</template>

<script>
const firebase = require("firebase")
const _ = require("lodash")
import payloads from "../payloads"

export default {
  name: "Authorize",
  metaInfo: {
    title: "Authorize",
    titleTemplate: "%s | Cycler",
  },
  mounted() {
    // grab code from url and initiate login sequence
    this.$nextTick(() => {
      const code = this.$route.query.code
      const redirect = process.env.VUE_APP_REDIRECTURI
      this.$store
        .dispatch("getTokens", { code, redirect })
        .then(() => {
          this.$store.dispatch("getUser").then(user => {
            firebase
              .auth()
              .signInWithEmailAndPassword(user.email, user.id)
              .then(result => {
                this.authorize(user, result.user)
              })
              .catch(error => {
                if (error.code == "auth/user-not-found") {
                  firebase.analytics().logEvent("sign_up", { method: "Spotify" })
                  firebase
                    .auth()
                    .createUserWithEmailAndPassword(user.email, user.id)
                    .then(result => {
                      this.authorize(user, result.user)
                    })
                } else {
                  console.error("Unknown Login Error: ", error)
                  this.$router.push("/")
                }
              })
          })
        })
        .catch(() => {
          console.error("User Denied Application")
          this.$router.push("/")
        })
    })
  },
  methods: {
    async authorize(user, fUser) {
      const u = { ...user, uid: fUser.uid }
      await this.$store.dispatch("setUser", u)
      this.$store.dispatch("userListener")
      this.$store.dispatch("playlistsListener").then(playlists => {
        firebase.analytics().logEvent("login", { method: "Spotify" })

        if (playlists.length == 0) {
          this.$store.dispatch("setClass", payloads.template).then(() => {
            this.$router.push("/home")
          })
        } else {
          this.$router.push("/home")
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
#title-text {
  font-size: 65px;
  margin-top: 35vh;
  margin-bottom: 0px;
}
#title {
  height: 80px;
  margin-top: 35vh;
  margin-bottom: 0px;
}
</style>

<template>
  <v-container class="container" fluid v-if="device">
    <v-row id="first-row">
      <v-btn class="borderless" color="white" rounded x-large depressed outlined dark>
        {{ millisToMinutesAndSeconds() }}
      </v-btn>
      <v-btn
        @click="togglePlaying()"
        class="borderless"
        color="white"
        id="play-button"
        rounded
        x-large
        depressed
        outlined
        dark
      >
        <i v-if="current && current.is_playing" class="fas fa-pause"></i>
        <i v-else class="fas fa-play"></i>
      </v-btn>
      <v-btn @click="next()" class="borderless" color="white" rounded x-large depressed outlined dark>
        <i class="fas fa-forward"></i>
      </v-btn>
    </v-row>
    <v-row id="second-row">
      <div id="slider" :class="{ mobile, desktop: !mobile }">
        <v-slider color="white" v-model="progress" :min="0" :max="max"></v-slider>
      </div>
    </v-row>
  </v-container>
</template>

<script>
const debounce = require("lodash/debounce")

export default {
  name: "seeker",
  data() {
    return {
      progress: 0,
    }
  },
  watch: {
    current() {
      this.progress = this.current.progress_ms / 1000
    },
    progress() {
      const seeked = Math.abs(this.progress - this.current.progress_ms / 1000) > 2
      if (seeked) this.onSeek()
    },
  },
  methods: {
    async next() {
      // await this.$store.dispatch("getActiveDevice")
      await this.$store.dispatch("turnOffShuffle")
      await this.$store.dispatch("nextPlayer")
    },
    onSeek: debounce(async function() {
      // await this.$store.dispatch("getActiveDevice")
      await this.$store.dispatch("seekPlayer", this.progress * 1000)
    }, 50),
    async togglePlaying() {
      // await this.$store.dispatch("getActiveDevice")
      this.current.is_playing
        ? await this.$store.dispatch("pausePlayer")
        : await this.$store.dispatch("playPlayer")
      this.$emit("played")
    },
    millisToMinutesAndSeconds() {
      if (this.current) {
        var minutes = Math.floor(this.current.progress_ms / 60000)
        var seconds = ((this.current.progress_ms % 60000) / 1000).toFixed(0)
        return minutes + ":" + (seconds < 10 ? "0" : "") + seconds
      } else {
        return "0:00"
      }
    },
  },
  computed: {
    max() {
      return this.current && this.current.item ? this.current.item.duration_ms / 1000 : 360
    },
    device() {
      return this.$store.state.device
    },
    current() {
      return this.$store.state.current
    },
    mobile() {
      return this.$store.state.mobile
    },
  },
}
</script>

<style lang="scss" scoped>
.container {
  background-color: #242424;
  box-shadow: 0px 0 10px rgba(0, 0, 0, 0.8);
}
#first-row {
  display: flex;
  justify-content: space-evenly;
}
#second-row {
  display: flex;
  justify-content: center;
}
.borderless {
  opacity: 0.85;
  border: none;
}
#slider.desktop {
  cursor: pointer;
  width: 65vw;
}
#slider.mobile {
  cursor: pointer;
  width: 80vw;
}
</style>

<template>
  <v-dialog persistent id="dialog" v-model="dialog" fullscreen transition="dialog-top-transition">
    <v-card class="mx-auto" color="info">
      <div>
        <v-btn id="back-btn" @click="$router.push('account')" rounded outlined x-large>
          <i class="fa fa-arrow-left" aria-hidden="true"></i>
        </v-btn>
      </div>
      <v-card-title style="padding-bottom: 0px; padding-top: 24px; display: flex; justify-content: center">
        Subscription
      </v-card-title>
      <img id="logo" src="@/assets/app-logo.png" />
      <div style="display: flex; flex-direction: column; align-items: center">
        <v-chip style="border: none" outlined v-if="subscription" class="ma-2" color="success">
          active - {{ subscription.plan.interval == "month" ? "monthly" : "yearly" }}
        </v-chip>
        <v-chip v-else style="border: none" outlined class="ma-2" color="grey">
          inactive
        </v-chip>
        <v-chip
          style="border: none; margin-top: -12px !important; color: grey"
          outlined
          v-if="subscription && subscription.trial_end"
          class="ma-2"
        >
          trial ends {{ getDate(subscription.trial_end) }}
        </v-chip>
      </div>
      <v-expansion-panels accordion>
        <v-expansion-panel v-if="subscription">
          <v-expansion-panel-header class="expansion-header">
            Next Billing Date
          </v-expansion-panel-header>
          <v-expansion-panel-content class="expansion-content">
            {{ getDate(subscription.current_period_end) }}
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel v-if="subscription">
          <v-expansion-panel-header class="expansion-header">
            Next Billing Amount
          </v-expansion-panel-header>
          <v-expansion-panel-content class="expansion-content">
            {{ getDollarsAndCents(subscription.items.data[0].price.unit_amount) }}
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel readonly @click="checkoutToggled = !checkoutToggled" v-if="!subscription">
          <v-expansion-panel-header class="expansion-header">
            Activate Subscription
          </v-expansion-panel-header>
        </v-expansion-panel>
        <v-expansion-panel
          @click="upgradeSubscription()"
          readonly
          v-if="subscription && subscription.plan.interval == 'month'"
        >
          <v-expansion-panel-header class="expansion-header">
            Upgrade to Annual
          </v-expansion-panel-header>
        </v-expansion-panel>
        <v-expansion-panel @click="launchCustomerPortal()" readonly v-if="subscription">
          <v-expansion-panel-header class="expansion-header">
            Update Credit Card
          </v-expansion-panel-header>
        </v-expansion-panel>
        <v-expansion-panel @click="launchCustomerPortal()" readonly v-if="subscription">
          <v-expansion-panel-header class="expansion-header">
            Redeem Coupon
          </v-expansion-panel-header>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header class="expansion-header">
            Need Help?
          </v-expansion-panel-header>
          <v-expansion-panel-content class="expansion-content">
            for questions about your subscription or billing in general, please email billing@cycler.io
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel v-if="subscription" readonly>
          <v-expansion-panel-header @click="cancelSubscription()" class="expansion-header">
            Cancel Subscription
          </v-expansion-panel-header>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card>
    <checkout :open="checkoutToggled" :persistant="false" @closed="checkoutToggled = false"></checkout>
    <upgrade
      :open="upgradeToggled"
      :invoice="invoice"
      :prorationDate="prorationDate"
      @closed="
        upgradeToggled = false
        getSubscription()
      "
    ></upgrade>
    <customFooter></customFooter>
    <v-overlay v-if="loading" style="display: flex; justify-content: center; align-items: center;">
      <v-progress-circular indeterminate color="primary"></v-progress-circular
    ></v-overlay>
  </v-dialog>
</template>

<script>
import stripe from "@/store/stripe"
import checkout from "@/components/checkout"
import upgrade from "@/components/upgrade"
import moment from "moment"
import customFooter from "@/components/footer"

import { loadStripe } from "@stripe/stripe-js"
const firebase = require("firebase")
const pageName = "Subscription"
export default {
  name: pageName,
  components: {
    checkout,
    upgrade,
    customFooter,
  },
  metaInfo: {
    title: pageName,
    titleTemplate: "%s | Cycler",
  },
  data() {
    return {
      dialog: true,
      checkoutToggled: false,
      upgradeToggled: false,
      loading: true,
      stripe: null,
      invoice: null,
      subscription: null,
      prorationDate: null,
    }
  },
  created() {
    loadStripe(process.env.VUE_APP_STRIPEPUBLICKEY).then(stripe => {
      this.stripe = stripe
    })
  },
  async mounted() {
    firebase.analytics().logEvent("page_view", { page_title: pageName })
    await this.getSubscription()
    this.loading = false
  },
  methods: {
    async launchCustomerPortal() {
      this.loading = true
      const response = await fetch(process.env.VUE_APP_API + "/createCustomerPortal", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          customerId: this.subscription.customer,
          returnUrl: window.location.href,
        }),
      })
      const { url } = await response.json()
      window.location.replace(url)
    },
    getDollarsAndCents(num) {
      let dollars = num / 100
      return dollars.toLocaleString("en-US", { style: "currency", currency: "USD" })
    },
    async getSubscription() {
      const id = this.user.subscription
      id ? (this.subscription = await stripe.getSubscription(id)) : null
    },
    flashMessage(content, color) {
      this.$snackbar.flash({ content, color })
    },
    async cancelSubscription() {
      const res = await this.$confirm("are you sure you want to cancel your subscription to cycler?", {
        title: "confirmation",
      })
      if (res) {
        this.loading = true
        const response = await fetch(process.env.VUE_APP_API + "/cancelSubscription", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            subscriptionId: this.user.subscription,
            userId: this.user.uid,
          }),
        })
        const data = await response.json()
        this.subscription = null
        this.flashMessage("successfully cancelled subscription", "success")
        this.loading = false
      }
    },
    async upgradeSubscription() {
      this.loading = true
      await this.getProratedInvoice()
      this.upgradeToggled = !this.upgradeToggled
      this.loading = false
    },
    async getProratedInvoice() {
      this.prorationDate = Math.floor(Date.now() / 1000)
      const items = [
        {
          id: this.subscription.items.data[0].id,
          price: process.env.VUE_APP_STRIPEYEARLY,
        },
      ]
      const response = await fetch(process.env.VUE_APP_API + "/retrieveUpcoming", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          customer: this.subscription.customer,
          subscription: this.subscription.id,
          subscription_items: items,
          subscription_proration_date: this.prorationDate,
        }),
      })
      const invoice = await response.json()
      this.invoice = invoice
      return
    },
    getDate(timestamp) {
      return moment.unix(timestamp).format("MMMM Do, YYYY")
    },
  },
  computed: {
    user() {
      return this.$store.state.user
    },
  },
}
</script>

<style lang="scss" scoped>
#logo {
  height: 60px;
  margin-top: 12px;
}
#back-btn {
  position: fixed;
  top: 7px;
  left: 7px;
  opacity: 0.85;
  border: none;
}
#logout-btn {
  margin-top: 28px;
}
.expansion-header {
  background-color: #1c1c1c;
}
.expansion-content {
  color: gray;
  background-color: #1c1c1c;
  text-align: left;
}
</style>
